import { ReactComponent as NothingSearchGridImage } from "../../../../assets/images/nodata_search_grid_bg.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow_right_purple3.svg";
import { useTranslation } from "react-i18next";
import classes from "./PostEmpty.module.scss";
import { getSessionUserFromStorage } from "../../../../service/Session.service";
import JoinOverlapComponent from "../../../../components/common/JoinOverlapComponent";
import { useEffect, useRef } from "react";
import { goTo } from "../../../../hooks/navigate/NavigateFunction";
import PostStore from "../../../../store/PostStore";
import PostRecommendUserWithFollow from "../following/PostRecommendUserWithFollow";
import AuthStore from "../../../../store/AuthStore";
import { observer } from "mobx-react";

const PostEmpty = observer(
  ({ tabIdx, isLoading }: { tabIdx: number; isLoading: boolean }) => {
    const { t } = useTranslation();
    const isAuthenticated = useRef<boolean>(false);

    useEffect(() => {
      void getSessionUserFromStorage().then((res) => {
        isAuthenticated.current = res !== null ? true : false;
      });
    }, []);

    return (
      <div style={{ alignItems: "center" }}>
        <NothingSearchGridImage style={{ width: "100%" }} />
        {!AuthStore.sessionUser?.nickname ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              flexDirection: "column",
              position: "absolute",
              top: 300,
              right: 0,
              left: 0,
            }}
          >
            <JoinOverlapComponent>
              <div style={{ width: "100%", height: "380px" }} />
            </JoinOverlapComponent>
          </div>
        ) : (
          !isLoading &&
          (tabIdx === 2 ? (
            PostStore.followingTabUserItemList.length === 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    flexDirection: "column",
                    position: "absolute",
                    top: 300,
                    right: 0,
                    left: 0,
                  }}
                >
                  <span className={classes.empty_txt1}>
                    {t("screen.post.message.emptyFollow1-v2")}
                  </span>
                  <span className={classes.empty_txt2}>
                    {t("screen.post.message.emptyFollow2-v2")}
                  </span>
                </div>
                <PostRecommendUserWithFollow />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  flexDirection: "column",
                  position: "absolute",
                  top: 400,
                  right: 0,
                  left: 0,
                }}
              >
                <span className={classes.empty_txt3}>
                  {t("screen.post.message.emptyFollow1-v3")}
                </span>
              </div>
            )
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  flexDirection: "column",
                  position: "absolute",
                  top: 400,
                  right: 0,
                  left: 0,
                }}
              >
                <span className={classes.empty_search}>
                  {t("screen.post.message.emptyPost")}
                </span>
                <div
                  className={classes.empty_btn_wrap}
                  onClick={() => {
                    goTo("/Search");
                  }}
                >
                  <span className={classes.empty_btn_text}>
                    {t("screen.post.button.search")}
                  </span>
                  <Arrow />
                </div>
              </div>
            </>
          ))
        )}
      </div>
    );
  }
);

export default PostEmpty;
