import React from "react";
import { observer } from "mobx-react";
import TitleHeader from "../../../components/common/TitleHeader";
import { HeaderItem } from "../../../constants/Header";
import { ReactComponent as ImgVolt } from "../../../assets/images/pop_benefit_volt.svg";
import { ReactComponent as Icon1volt } from "../../../assets/icons/220mall_1volt.svg";
import { ReactComponent as Icon3volt } from "../../../assets/icons/220mall_3volt.svg";
import { ReactComponent as Icon5volt } from "../../../assets/icons/icon_5volt.svg";
import { ReactComponent as Icon10volt } from "../../../assets/icons/icon_10volt.svg";
import { ReactComponent as IconQuestionVolt } from "../../../assets/icons/icon_volt_question.svg";
import { Trans, useTranslation } from "react-i18next";
import Modal from "react-modal";
import "./BenefitPop.scss";

const BenefitPop = observer(
  ({
    closeBtnClick,
    modalShow,
  }: {
    closeBtnClick: any;
    modalShow: boolean;
  }) => {
    const { t } = useTranslation();
    return (
      //   <Modal animationType="fade" transparent={true} visible={modalShow}>
      <Modal
        isOpen={modalShow}
        style={{
          overlay: {
            zIndex: 200,
          },
          content: {
            border: "unset",
            margin: 0,
            padding: 0,
            display: "flex",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        }}
      >
        <div id="benefitPop">
          <TitleHeader
            title={t("screen.volt.label.voltInfo")}
            isBack={false}
            rightItem={HeaderItem.CLOSE}
            onClickClose={closeBtnClick}
          />
          <div className="bx_content_container">
            <div className="bx_content_wrap">
              <ImgVolt />
              <Trans
                className={"txt_st1"}
                parent={"span"}
                i18nKey="screen.volt.label.voltInfoMessageDetail"
                components={[<span className={"txt_st1_bold"} />]}
              />
              <div className="txt_title_strong">
                {t("screen.volt.label.voltGetInfoMessage")}
              </div>

              <div className="bx_info_benefit">
                <Icon1volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.appAttendanceStampGuide")}
                </div>
                <Trans
                  className={"txt_info_cont"}
                  i18nKey={"screen.volt.label.appAttendanceStampDetail"}
                  values={{ voltAmount: 1 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
                <div className="txt_info_title">
                  {t("screen.volt.label.answerQnaGuide")}
                </div>
                <Trans
                  className={"txt_info_cont"}
                  i18nKey={"screen.volt.label.answerQnaDetail1"}
                  values={{ voltAmount: 1, day: 1, limit: 5 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
                <div>
                  <Trans
                    className={"txt_info_cont"}
                    i18nKey={"screen.volt.label.answerQnaDetail2"}
                  />
                </div>
                <div>
                  <Trans
                    className={"txt_info_cont"}
                    i18nKey={"screen.volt.label.answerQnaDetail3"}
                  />
                </div>
                <div className="txt_info_title">
                  {t("screen.volt.label.registMyApplianceGuide")}
                </div>
                <Trans
                  className={"txt_info_cont"}
                  i18nKey={"screen.volt.label.registMyApplianceDetail"}
                  values={{ voltAmount: 1, limit: 15 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
                <div className="txt_info_title">
                  {t("screen.volt.label.commentTitle")}
                </div>
                <Trans
                  className={"txt_info_cont"}
                  i18nKey={"screen.volt.label.commentDetail"}
                  values={{ voltAmount: 1, limit: 5 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
                <div className="txt_info_title">
                  {t("screen.volt.label.abTitle")}
                </div>
                <Trans
                  className={"txt_info_cont"}
                  i18nKey={"screen.volt.label.abDetail"}
                  values={{ voltAmount: 1, limit: 5 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
                <div className="txt_info_title">
                  {t("screen.volt.label.followTitle")}
                </div>
                <Trans
                  className={"txt_info_cont"}
                  i18nKey={"screen.volt.label.followDetail"}
                  values={{ voltAmount: 1, limit: 10 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
              </div>

              <div className="bx_info_benefit">
                <Icon3volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.writeArticleGuide")}
                </div>
                <div>
                  <Trans
                    i18nKey={"screen.volt.label.writeArticleDetail1"}
                    className="txt_info_cont"
                    values={{ voltAmount: 3, day: 1, limit: 2 }}
                    components={[<span className={"txt_info_cont_bold"} />]}
                  />
                </div>
                <div>
                  <Trans
                    i18nKey={"screen.volt.label.writeArticleDetail2"}
                    className="txt_info_cont"
                    values={{ voltAmount: 2 }}
                    components={[<span className={"txt_info_cont_bold"} />]}
                  />
                </div>

                <div className="txt_info_title">
                  {t("screen.volt.label.bestPickGuide")}
                </div>
                <div>
                  <Trans
                    i18nKey={"screen.volt.label.bestPickDetail"}
                    className="txt_info_cont"
                    values={{ voltAmount: 3, day: 1, limit: 2 }}
                    components={[<span className={"txt_info_cont_bold"} />]}
                  />
                </div>
              </div>

              <div className="bx_info_benefit">
                <Icon5volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.comment10Guide")}
                </div>
                <div>
                  <Trans
                    i18nKey={"screen.volt.label.comment10Detail"}
                    className="txt_info_cont"
                    values={{ voltAmount: 5, day: 1, limit: 1 }}
                    components={[<span className={"txt_info_cont_bold"} />]}
                  />
                </div>

                <div className="txt_info_title">
                  {t("screen.volt.label.like10Guide")}
                </div>
                <div>
                  <Trans
                    i18nKey={"screen.volt.label.like10Detail"}
                    className="txt_info_cont"
                    values={{ voltAmount: 5, day: 1, limit: 1 }}
                    components={[<span className={"txt_info_cont_bold"} />]}
                  />
                </div>
              </div>

              <div className="bx_info_benefit">
                <Icon10volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.appMemberRegistTitle")}
                </div>
                <Trans
                  i18nKey={"screen.volt.label.appMemberRegistDetail"}
                  className="txt_info_cont"
                  values={{ voltAmount: 10 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
                <div className="txt_info_title">
                  {t("screen.volt.label.inviteFriendsTitle")}
                </div>
                <Trans
                  i18nKey={"screen.volt.label.inviteFriendsDetail"}
                  className="txt_info_cont"
                  values={{ voltAmount: 10, limit: 10 }}
                  components={[<span className={"txt_info_cont_bold"} />]}
                />
              </div>

              <div className="bx_info_benefit">
                <IconQuestionVolt />
                <div className="txt_info_title">
                  {t("screen.volt.label.missionParticipateTitle")}
                </div>
                <Trans
                  i18nKey={"screen.volt.label.missionParticipateDetail"}
                  className="txt_info_cont"
                  components={[<span style={{ fontWeight: 700 }} />]}
                />
                {/* <div className="txt_info_title">{t("screen.volt.label.mallBuyTitle")}</div>
              <div className="txt_info_cont">
                {"\u2022"}
                {t("screen.volt.label.mallBuyDetail1")}
                <div style={{ fontWeight: "700" }}>{t("screen.volt.label.mallBuyDetail2")}</div>
              </div>
              <div className="txt_info_cont">{t("screen.volt.label.mallBuyDetail3")}</div> */}
              </div>
            </div>

            <div className="line_bar" />

            <div className="bx_notice">
              <div className="in_notice">
                <div className="txt_noti_title">
                  {t("screen.volt.label.noticeTitle")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail1")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail4")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail2")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail3")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail6")}
                </div>

                {/* <div className="txt_noti_cont">
                {"\u2022"}
                {t("screen.volt.label.noticeDetail5")}
                <div style={{ fontWeight: "700" }}>{t("screen.volt.label.noticeDetail5_1")}</div>
                {t("screen.volt.label.noticeDetail5_2")}
                {t("screen.volt.label.noticeDetail5_3")}
                <div style={{ fontWeight: "700" }}>{t("screen.volt.label.noticeDetail5_4")}</div>
                {t("screen.volt.label.noticeDetail5_5")}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default BenefitPop;
