import React, {
  CSSProperties,
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat.svg";
import { ReactComponent as BookmarkOffIcon } from "../../../assets/icons/bookmark_off.svg";
import { ReactComponent as BookmarkOnIcon } from "../../../assets/icons/bookmark_on.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { Post, Tag } from "../../../api/feed/model";
import LikeStore from "../../../store/LikeStore";
import BookmarkStore from "../../../store/BookmarkStore";
import FeedStore from "../../../store/FeedStore";
import AuthStore from "../../../store/AuthStore";
import { CommentTypeCode } from "../../../constants/Comment";
import MainStore, {
  INIT_LONG_NUM_OF_POSTS,
  INIT_NUM_OF_POSTS,
} from "../../../store/MainStore";
// import { ContentsPopupMenu } from "../../../components/common/ContentsPopupMenu";
import {
  MainFeedTypeCode,
  UserActTypeCode,
} from "../../../constants/FeedType.enum";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
import { NotificationModal } from "../../../components/modals";
import PostModifyStore from "../../../store/PostModifyStore";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
  ActTypeCode,
} from "../../../constants/ActHistory";
import ReportStore from "../../../store/ReportStore";
import { ReportTargetTypeCode } from "../../../constants/Report";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { getDateStr } from "../../../utils/datetime";
import { setPostViewCountUp } from "../../../api/post/api";
import { LikeTypeCode } from "../../../constants/Like";
import SearchStore from "../../../store/SearchStore";
import VideoPlayerWithFallback from "../../../components/common/VideoPlayerWithFallback";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import useFeedScreen, { FeedParentTypeCode } from "../hook/UseFeedScreen";
import ProsAndCons from "./FeedPostProsAndCons";
import { AccountFeedTypeCode } from "../../../constants/Account";
import MyHomeStore from "../../../store/MyHomeStore";
import { useTracker } from "../../../hooks/tracker";
import { FeedTypeCode } from "../../../constants/Feed";
import { follow, unfollow } from "../../../api/follow/api";
import FollowerStore from "../../../store/FollowerStore";
import {
  File,
  toHeight,
  toRatio,
  toRatioSizeFile,
} from "../../../api/file/model";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";

import MissionStore from "../../../store/MissionStore";
// import BadgeStore from "../../../store/BadgeStore";
import { BadgeCode } from "../../../constants/Badge.enum";
import { useTranslation } from "react-i18next";
import { Product } from "../../../api/product/model";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FeedPost.scss";
import { ContentsPopupMenu } from "../../../components/common/ContentsPopupMenu";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import CommentStore from "../../../store/CommentStore";
import PostDetailStore from "../../../store/PostDetailStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { observer } from "mobx-react";
import { ShareInfo, onShare } from "../../../utils/share";
import { getSessionUserFromStorage } from "../../../service/Session.service";
import PostStore from "../../../store/PostStore";
import FeedDetailStore from "../../../store/FeedDetailStore";
import TagStore from "../../../store/TagStore";

export interface FeedPostProps {
  parentType: FeedParentTypeCode;
  post: Post;
  sectionIndex: number; //NOTE : Only for feed from MainTab
  itemIndex: number;
  styleWrapper?: CSSProperties;
  updatePost?: (
    feedId: number,
    actionType: UserActTypeCode,
    sectionIndex: number,
    itemIndex: number
  ) => void;
  visible: boolean;
  onFollow?: (isfllow: boolean, index: number) => void;
  onReport?: (feedId: number) => void;
  onDelete?: (feedId: number) => void;
  lifecareProductId?: number;
  setInitialIndex?: (feedId: number) => void;
  isMainSection?: boolean;
}

export const FeedPost = observer(
  ({
    onDelete,
    onReport,
    onFollow,
    visible,
    parentType,
    post,
    sectionIndex,
    itemIndex,
    styleWrapper,
    updatePost,
    lifecareProductId,
    setInitialIndex,
    isMainSection = false,
  }: FeedPostProps) => {
    const { widthApp } = CommonStore;
    const { t } = useTranslation();
    const videoViewRef: any = createRef();
    const [isClickHomeItem, setIsClickHomeItem] = useState(true);
    const show_up_product_tag = getStringRemoteConfig("show_up_product_tag");

    // const width = responsiveWidth(100);
    // const carouselOffset = responsiveWidth(4.1);

    const gap = 0; //20;
    // const selfWidth = width - carouselOffset * 2 - gap * 2;
    const { popPostFromMain, popFromMainList, refreshPopularTag } = MainStore;
    const { popPostFromFeed, addFeedContentLines, popPostFromFeedWithFeedId } =
      FeedStore;
    const { saveLike, cancelLike } = LikeStore;
    const { saveBookmark, cancelBookmark } = BookmarkStore;
    const { sessionUser } = AuthStore;
    const { openReportBottomSheet } = ReportStore;
    // const [carouselIdx, setCarouselIdx] = useState(0);
    const [sliderIdx, setSliderIdx] = useState(0);

    const [showMore, setShowMore] = useState<boolean>(true);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showPostLike, setShowPostLike] = useState<boolean>(false);
    // const config = useRemoteConfig();

    const { detailPostListAttr5 } = useFeedScreen(parentType);

    //PPIT210117-4818 actHistory 처리 시 MAIN 화면여부 오류 수정
    const openedFromMainTab = parentType === "MAIN_TAB_VERTICAL_INFINITE_POSTS";

    const { reportPostCount, setReportPostCount, popPostFromSearch } =
      SearchStore;

    const {
      nickname,
      feedId,
      profileUrl,
      productList,
      createdDatetime,
      profileBackgroundColor,
    } = post;

    const { track } = useTracker();

    // addFeedContentLines(feedId, 10);

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const [viewPL, setViewPL] = useState<Product[]>(
      productList && productList.length > 0
        ? productList?.filter((product) => !product.openApiFlag)
        : []
    );

    const handlePostUpdate = useCallback(
      (
        feedId: number,
        actionType: UserActTypeCode,
        sectionIndex: number,
        itemIndex: number
      ) => {
        MainStore.updateFeed(actionType, feedId);
        MainStore.updateMainList(actionType, itemIndex, sectionIndex);
        MainStore.updateMainPopularList(actionType, feedId);
        PostStore.updatePostStoreList(actionType, feedId);
        FeedDetailStore.updatePostInAllFeedListStore(actionType, feedId);
        MyHomeStore.sync(feedId, AccountFeedTypeCode.POST, actionType);
        TagStore.sync(feedId, FeedTypeCode.POST, actionType);
        SearchStore.syncPost(feedId, actionType);

        updatePost && updatePost(feedId, actionType, sectionIndex, itemIndex);
      },
      [updatePost]
    );

    const clickTag = (tag: Tag) => {
      CommonStore.callActHistory(
        ActHistoryTypeCode.TAG_CLICK,
        (tag.tagId ?? 0).toString(),
        openedFromMainTab ? ActPageCode.MAIN : ActPageCode.DETAIL_POST_LIST,
        openedFromMainTab ? ActSectionCode.POST : undefined,
        (globalItemIndex ?? 0).toString()
      );
      goTo(`/tag/${tag.tagId}`, {
        state: { tagId: tag.tagId },
      });
    };

    //NOTE : 활동이력 호출 시 사용되는 Index. 화면상에 보여지는 모든 FeedPost 중 몇 번째 item인지를 나타냅니다.
    const globalItemIndex = useMemo(() => {
      if (sectionIndex >= 0) {
        const globalPostIndex = sectionIndex;
        if (sectionIndex > 0) {
          sectionIndex--;
        }
        if (sectionIndex > 5) {
          sectionIndex--;
        }
        if (sectionIndex > 10) {
          sectionIndex--;
        }

        return sectionIndex;
      } else {
        return itemIndex;
      }
    }, [sectionIndex, itemIndex]);

    //NOTE : Smoke Test 에서 사용되는 Index. 한 번에 paging 해온 item 중에 몇 번째 item인지를 나타냅니다.
    const itemIndexInFetchChunk = useMemo(() => {
      let itemIndexInFetchChunk = itemIndex;

      const sliceIndex = INIT_NUM_OF_POSTS * 3 - INIT_LONG_NUM_OF_POSTS;
      const startIndexOfInfinitePaging =
        9 + INIT_LONG_NUM_OF_POSTS - sliceIndex;

      if (sectionIndex >= 9 && sectionIndex < startIndexOfInfinitePaging) {
        itemIndexInFetchChunk = sectionIndex - 9;
      } else if (sectionIndex >= startIndexOfInfinitePaging) {
        itemIndexInFetchChunk =
          (sectionIndex - startIndexOfInfinitePaging) % 10;
      }

      return itemIndexInFetchChunk;
    }, [sectionIndex, itemIndex]);

    //PPIT210117-4818 취소 차리 시 attr6에 CANCEL 반영
    const callActHistoryByHistoryType = useCallback(
      (
        historyType: string,
        sectionCode?: string,
        attr4?: string,
        attr6?: string
      ) => {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: historyType,
          actHistoryTargetId: feedId.toString(),
          actPageCode: ActPageCode.MAIN,
          actSectionCode: sectionCode,
          attr1: globalItemIndex.toString(),
          attr2: post.likeCount.toString(),
          attr3: post.commentCount.toString(),
        };

        if (!openedFromMainTab) {
          actHistory.actPageCode = ActPageCode.DETAIL_POST_LIST;
          actHistory.actSectionCode = undefined;
          actHistory.attr5 = detailPostListAttr5;
        }

        if (attr4) {
          actHistory.attr4 = attr4;
        }
        if (attr6) {
          actHistory.attr6 = attr6;
        }
        void sendActHistory(actHistory);
      },
      [feedId, globalItemIndex, post, openedFromMainTab, detailPostListAttr5]
    );

    const [likestate, setLikeState] = useState(post.likeYn);
    const [bookmarkYnstate, setBookmarkYnState] = useState(post.bookmarkYn);
    const [likeCountstate, setlikeCountState] = useState(post.likeCount);
    const [createMemberUuidstate, setCreateMemberUuidState] = useState(
      post.createMemberUuid
    );

    useEffect(() => {
      setLikeState(post.likeYn);
      setBookmarkYnState(post.bookmarkYn);
      setlikeCountState(post.likeCount);
      setCreateMemberUuidState(post.createMemberUuid);
    }, [post]);

    const trackViewPostDetail = (feedId: number, moreYn: boolean) => {
      track("view_post_detail", {
        is_button: moreYn,
        content_id: feedId,
        content_title: post.feedTitle,
        content_type: FeedTypeCode.POST,
        like_count: likeCountstate ? likeCountstate : 0,
        comment_count: post.commentCount ? post.commentCount : 0,
        review_point: post.rating ? post.rating : "-",
        review_product_count: post.productList ? post.productList.length : 0,
        review_product_id: post.productList
          ? post.productList.map((p) => p.productId)
          : [],
        review_product_name: post.productList
          ? post.productList.map((p) => p.productName)
          : [],
        tag_count: post.tagList ? post.tagList.length : 0,
        tag: post.tagList ? post.tagList.map((t) => t.tagName) : [],
        image_count: post.imageCount,
        video_count: post.videoCount,
        paragraph_count: post.feedContentCount,
        is_pros: post.advantage ? "Y" : "N",
        is_cons: post.disadvantage ? "Y" : "N",
        resolution: post.fileList
          .filter((x) => x.thumbnailYn === "Y")
          .map((x) => x.fileResolution)
          .toString(),
      });
    };

    // const onClickPostDetailBtnDefault = (feedId: number) => {
    //   navigate(`/posts/feed/${feedId}`);
    // };

    const clickPostDetailBtn = (feedId: number) => {
      callActHistoryByHistoryType(
        ActHistoryTypeCode.POST_READ,
        ActSectionCode.POST,
        ActTypeCode.SHOW_DETAIL
      );
      setInitialIndex && setInitialIndex(feedId);

      goTo(`/posts/feed/${feedId}`, {
        state: {
          feedId: feedId,
          postIndex: itemIndex,
          listIndex: sectionIndex,
          lifecareProductId: lifecareProductId,
        },
      });

      trackViewPostDetail(feedId, false);
    };

    const onLikeListButtonPress = (feedId: number) => {
      setInitialIndex && setInitialIndex(feedId);
      goTo("/LikeScreen", {
        state: {
          feedId: feedId ?? 0,
          feedType: CommentTypeCode.POST,
        },
      });
    };

    const onCommentButtonPress = (feedId: number) => {
      setInitialIndex && setInitialIndex(feedId);
      goTo(`/${CommentTypeCode.POST}/${feedId}/comment`, {
        state: {
          feedId: feedId ?? 0,
          feedType: CommentTypeCode.POST,
          feedAuthorId: post.createMemberUuid,
        },
      });
    };

    const onLikeButtonPress = useCallback(
      async (feedId: number, likeYn: string) => {
        if (likestate === "Y") {
          setLikeState("N");
          setlikeCountState((old) => old - 1);
          post.likeYn = "N";
          post.likeCount = post.likeCount - 1;
          await cancelLike(
            feedId,
            "POST",
            () => {
              //PPIT210117-4818 LIKE 취소 시 actHistory 처리 추가
              callActHistoryByHistoryType(
                ActHistoryTypeCode.LIKE,
                undefined,
                undefined,
                "CANCEL"
              );
              // feedId, like = "N" 전달
              handlePostUpdate(
                feedId,
                UserActTypeCode.CANCEL_LIKE,
                sectionIndex,
                itemIndex
              );
            },
            () => {
              // fail
              setLikeState("Y");
              setlikeCountState((old) => old + 1);
              post.likeYn = "Y";
              post.likeCount = post.likeCount + 1;
            }
          );
        } else {
          setLikeState("Y");
          setlikeCountState((old) => old + 1);
          post.likeYn = "Y";
          post.likeCount = post.likeCount + 1;
          await saveLike(
            feedId,
            "POST",
            () => {
              callActHistoryByHistoryType(ActHistoryTypeCode.LIKE);
              track("click_like_button", {
                content_id: feedId,
                content_title: post.feedTitle,
                content_type: FeedTypeCode.POST,
                like_count: likeCountstate ? likeCountstate : 0,
                comment_count: post.commentCount ? post.commentCount : 0,
              });
              handlePostUpdate(
                feedId,
                UserActTypeCode.SAVE_LIKE,
                sectionIndex,
                itemIndex
              );
            },
            () => {
              // fail
              setLikeState("N");
              setlikeCountState((old) => old - 1);
              post.likeYn = "N";
              post.likeCount = post.likeCount - 1;
            }
          );
        }
      },
      [
        likestate,
        likeCountstate,
        cancelLike,
        itemIndex,
        post,
        saveLike,
        sectionIndex,
        track,
        updatePost,
      ]
    );

    const onBookmarkButtonPress = useCallback(
      async (feedId: number, markYn: string) => {
        if (bookmarkYnstate === "Y") {
          post.bookmarkYn = "N";
          setBookmarkYnState("N");
          await cancelBookmark(
            feedId,
            "POST",
            () => {
              //PPIT210117-4818 북마크 취소 시 actHistory 처리 추가
              callActHistoryByHistoryType(
                ActHistoryTypeCode.BOOKMARK,
                undefined,
                undefined,
                "CANCEL"
              );
              handlePostUpdate(
                feedId,
                UserActTypeCode.CANCEL_BOOKMARK,
                sectionIndex,
                itemIndex
              );
            },
            () => {
              // fail
              post.bookmarkYn = "Y";
              setBookmarkYnState("Y");
            }
          );
        } else {
          post.bookmarkYn = "Y";
          setBookmarkYnState("Y");
          await saveBookmark(
            feedId,
            "POST",
            () => {
              callActHistoryByHistoryType(ActHistoryTypeCode.BOOKMARK);
              track("click_bookmark", {
                content_id: feedId,
                content_title: post.feedTitle,
                content_type: FeedTypeCode.POST,
                like_count: likeCountstate ? likeCountstate : 0,
                comment_count: post.commentCount ? post.commentCount : 0,
              });
              handlePostUpdate(
                feedId,
                UserActTypeCode.SAVE_BOOKMARK,
                sectionIndex,
                itemIndex
              );
            },
            () => {
              // fail
              post.bookmarkYn = "N";
              setBookmarkYnState("N");
            }
          );
        }
      },
      [
        cancelBookmark,
        saveBookmark,
        bookmarkYnstate,
        likeCountstate,
        callActHistoryByHistoryType,
        itemIndex,
        post,
        sectionIndex,
        track,
        updatePost,
      ]
    );

    const ratioImg =
      post.fileList.length > 0 && post.fileList[0].fileResolution !== null
        ? toRatio(post.fileList[0].fileResolution ?? "")
        : 1;

    const [audioMute, setAudioMute] = useState(true);
    const [postLikeX, setPostLikeX] = useState(0);
    const [postLikeY, setPostLikeY] = useState(0);

    const [lastTap, setLastTab] = useState<number>();
    const handleDoubleTap = async (event: any) => {
      setPostLikeX(event.nativeEvent.locationX);
      setPostLikeY(event.nativeEvent.locationY);
      const now = Date.now();
      const DOUBLE_PRESS_DELAY = 300;
      if (lastTap && now - lastTap < DOUBLE_PRESS_DELAY) {
        setShowPostLike(true);

        // haptic
        const options = {
          enableVibrateFallback: true,
          ignoreAndroidSystemSettings: false,
        };
        // Trigger haptic feedback
        // trigger("impactLight", options);

        if (likestate === "N") {
          await onLikeButtonPress(feedId, likestate);
        }
      } else {
        setLastTab(now);
      }
    };

    // const renderImageItem = (fid: number, index: number, item: any) => {
    //   const filePath: string = item?.filePath;
    //   const thumbnailPath: string = item?.thumbnailFilePath;

    //   const sourceUri: string = PUBLIC_BUCKET_URL + filePath;
    //   const thumbnailUri: string = PUBLIC_BUCKET_URL + thumbnailPath;

    //   return item?.fileType == "VIDEO" ? (
    //     <div
    //       onClick={(event) => {
    //         void handleDoubleTap(event);
    //       }}
    //     >
    //       {index == carouselIdx ? (
    //         <div>
    //           {/* <VideoPlayerWithFallback
    //             disableFullscreen={true}
    //             className="[
    //               video_item2,
    //               { width: selfWidth, aspectRatio: ratioImg, borderRadius: 6 },
    //             ]}
    //             sourceUri={encodeURI(sourceUri)}
    //             defAudioMute={audioMute}
    //             onMute={(mute: boolean) => {
    //               setAudioMute(mute);
    //             }}
    //             disabled={!visible}
    //             resizeOk={true}
    //             carouselIdx={index}
    //             thumbnailurl={encodeURI(thumbnailUri)}
    //             keystring={
    //               sectionIndex == -1
    //                 ? itemIndex.toString()
    //                 : sectionIndex.toString()
    //             }
    //             thumbnail={{ uri: encodeURI(thumbnailUri) }}
    //             poster={encodeURI(thumbnailUri)}
    //           /> */}
    //         </div>
    //       ) : (
    //         <div>
    //           {/* <FastImageWithFallback
    //             index={itemIndexInFetchChunk}
    //             carouselIdx={index}
    //             resizeMode={"cover"}
    //             wrapperStyle={{ aspectRatio: ratioImg }}
    //             className="[
    //               image_item,
    //               { aspectRatio: ratioImg, width: selfWidth, borderRadius: 6 },
    //             ]}
    //             source={{ uri: thumbnailUri }}
    //             fallbackImageUri={sourceUri}
    //           /> */}
    //         </div>
    //       )}
    //     </div>
    //   ) : (
    //     <div
    //       onClick={(event) => {
    //         void handleDoubleTap(event);
    //       }}
    //     >
    //       <div>
    //         {/* <FastImageWithFallback
    //           index={itemIndexInFetchChunk}
    //           carouselIdx={index}
    //           wrapperStyle={{ aspectRatio: ratioImg }}
    //           className="[
    //             image_item,
    //             { aspectRatio: ratioImg, width: selfWidth, borderRadius: 6 },
    //           ]}
    //           source={{ uri: thumbnailUri }}
    //           fallbackImageUri={thumbnailUri}
    //         > */}
    //         <div className="image_item" className="image_item} key={index}>
    //           {visible && index == carouselIdx && (
    //             // <Image
    //             //   resizeMode={"cover"}
    //             //   className="[{ width: "100%", height: "100%" }]}
    //             //   source={{ uri: sourceUri }}
    //             // />
    //             <img src={sourceUri} alt={"logo image " + item} />
    //           )}
    //         </div>
    //         {/* </FastImageWithFallback> */}
    //       </div>
    //     </div>
    //   );
    // };

    const renderTags = (tags: any) => {
      return tags.map((eachTag: Tag) => {
        return (
          <TouchableWithAuthCheck
            className="tag_item"
            key={eachTag.tagId}
            onPress={() => clickTag(eachTag)}
          >
            <div>
              <div className="tag_text">#{eachTag.tagName}</div>
            </div>
          </TouchableWithAuthCheck>
        );
      });
    };

    const toggleShowMoreBtn = (isOpen: boolean, feedId: number) => {
      if (showMore) {
        if (isOpen) {
          callActHistoryByHistoryType(
            ActHistoryTypeCode.POST_READ,
            ActSectionCode.POST,
            ActTypeCode.SHOW_MORE
          );
          //조회수 증가
          void setPostViewCountUp(feedId);

          trackViewPostDetail(feedId, true);
        }

        setShowMore((prev) => !prev);
      }
    };

    const closeNotiModal = () => {
      setShowDeletePopup(false);
    };

    const clickConfirmBtn = async () => {
      // const res = await PostModifyStore.deletePostAtList(feedId);
      // if (res) {
      //   sectionIndex && sectionIndex >= 0
      //     ? void popPostFromMain(itemIndex, sectionIndex)
      //     : void popPostFromFeed(itemIndex);
      // }
      // MyHomeStore.sync(
      //   feedId,
      //   AccountFeedTypeCode.POST,
      //   UserActTypeCode.DELETE
      // );
      setShowDeletePopup(false);

      handlePostUpdate(feedId, UserActTypeCode.DELETE, sectionIndex, itemIndex);

      if (onDelete) {
        onDelete(feedId);
      }
    };

    // const itemWidth: number = selfWidth + 20;

    // const handleSnapToItem = (idx: number) => {
    //   setCarouselIdx(idx);
    // };

    const onToast = useCallback(
      (follow: boolean) => {
        CommonStore.setToastOption({
          show: true,
          message1: follow
            ? t("screen.myHome.message.followToast", { nickname: nickname })
            : t("screen.myHome.message.unfollowToast", { nickname: nickname }),
        });
      },
      [nickname, t]
    );

    const followUser = useCallback(
      (memberUuid: string) =>
        new Promise((resolve) => {
          if (sessionUser?.memberUuid) {
            const actPageCode = openedFromMainTab
              ? ActPageCode.MAIN
              : ActPageCode.DETAIL_POST_LIST;
            CommonStore.callActHistory(
              ActHistoryTypeCode.USER_FOLLOWING,
              memberUuid,
              actPageCode,
              undefined,
              globalItemIndex.toString()
            );
            void follow(memberUuid)
              .then((res) => {
                if (res) {
                  if (onFollow) {
                    onFollow(true, itemIndex);
                  }
                  onToast(true);
                  FollowerStore.handlePostFollowUpdate(
                    UserActTypeCode.FOLLOW,
                    memberUuid
                  );
                  // void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
                }
                resolve(true);
              })
              .catch((error) => {
                resolve(false);
              });
          } else {
            // 회원가입 안내 모달 오픈
            MainStore.setShowJoinModal(true);
            resolve(false);
          }
        }),
      [
        sessionUser,
        openedFromMainTab,
        globalItemIndex,
        onFollow,
        itemIndex,
        onToast,
      ]
    );

    const unFollowUser = useCallback(
      (memberUuid: string) =>
        new Promise((resolve) => {
          if (sessionUser?.memberUuid) {
            //PPIT210117-4818 UNFOLLOW 시 actHistory 처리 추가
            CommonStore.callActHistory(
              ActHistoryTypeCode.USER_FOLLOWING,
              memberUuid,
              ActPageCode.FOLLOWING_MANAGEMENT,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              "CANCEL"
            );

            void unfollow(memberUuid)
              .then((res) => {
                if (res) {
                  if (onFollow) {
                    onFollow(false, itemIndex);
                  }
                  onToast(false);
                  FollowerStore.handlePostFollowUpdate(
                    UserActTypeCode.UN_FOLLOW,
                    memberUuid
                  );
                }
                resolve(true);
              })
              .catch((error) => {
                resolve(false);
              });
          } else {
            // 회원가입 안내 모달 오픈
            MainStore.setShowJoinModal(true);
            resolve(false);
          }
        }),
      [sessionUser, onFollow, itemIndex, onToast]
    );

    const settings: Settings = {
      dots: post.fileList && post.fileList.length > 1,
      infinite: false,
      arrows: false,
    };

    const getRatioStyle = useCallback(
      (items: File[]): CSSProperties => {
        const DEFAULT_RATIO_STRING = "100%";
        let result = DEFAULT_RATIO_STRING;
        const getValue = (file: File) => {
          if (file) {
            const ratio = toRatioSizeFile(file.fileResolution || "");
            if (ratio) {
              result = ratio.ratioPercentString;
            }
          }
        };
        if (items.length > 0) {
          const firstImage = items?.find(
            (item) => item.fileType === "IMAGE" && item?.fileResolution
          );
          if (firstImage) {
            getValue(firstImage);
          } else {
            const firstFile = items?.find((item) => item?.fileResolution);
            firstFile && getValue(firstFile);
          }
        }
        return { paddingTop: result };
      },
      [post]
    );

    const settingHomeAppList: Settings = {
      infinite: false,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
      beforeChange: () => setIsClickHomeItem(false),
      afterChange: () => {
        setTimeout(() => {
          setIsClickHomeItem(true);
        }, 100);
      },
    };

    return (
      <div
        id="FeedPost"
        className="post_item"
        key={post.feedId}
        style={styleWrapper}
        aria-label={`Feed Container${itemIndexInFetchChunk}`}
        // onLayout={(e) => {
        //   post.viewHeight = e.nativeEvent.layout.height + 40;
        // }}
      >
        <div
          className="post_header"
          aria-label={`Feed header${itemIndexInFetchChunk}`}
        >
          <TouchableWithAuthCheck
            className="post_header_left"
            onPress={() => {
              setInitialIndex && setInitialIndex(feedId);
              goTo(`/user/${nickname}`, {
                state: {
                  targetUserId: createMemberUuidstate,
                  nickname: nickname,
                  profileUrl: profileUrl,
                  profileBackgroundColor: profileBackgroundColor,
                },
              });
            }}
          >
            <FastImageWithFallback
              className="user_img"
              source={{
                uri: `${PUBLIC_BUCKET_URL}${post.profileUrl}`,
              }}
            />
            <div className="info_wrap">
              <div className="info_text1">{nickname ? nickname : "Guest"}</div>
              <div className="info_text2">{getDateStr(createdDatetime)}</div>
            </div>
          </TouchableWithAuthCheck>

          <div className="post_header_right">
            {post.followCount === 0 ||
            post.followCount === null ||
            post.followCount === undefined
              ? createMemberUuidstate !== sessionUser?.memberUuid && (
                  <div className="on_follow_btn">
                    <TouchableWithAsyncTask
                      onPress={async () =>
                        createMemberUuidstate &&
                        followUser(createMemberUuidstate).then()
                      }
                      authCheckProps={{
                        enabled: true,
                        authScreenType: "MODAL",
                      }}
                    >
                      <div className="on_follow_text">{"팔로우"}</div>
                    </TouchableWithAsyncTask>
                  </div>
                )
              : createMemberUuidstate !== sessionUser?.memberUuid && (
                  <TouchableWithAsyncTask
                    className="off_follow_btn"
                    onPress={async () => {
                      createMemberUuidstate &&
                        unFollowUser(createMemberUuidstate).then();
                    }}
                  >
                    <div className="off_follow_text">{"팔로잉"}</div>
                  </TouchableWithAsyncTask>
                )}

            <div aria-label={`Show more${itemIndexInFetchChunk}`}>
              <ContentsPopupMenu
                needAuth={false}
                optionTypesToShow={
                  createMemberUuidstate === sessionUser?.memberUuid
                    ? ["MODIFY", "DELETE"]
                    : ["REPORT"]
                }
                // optionTypesToShow={["MODIFY", "DELETE", "REPORT"]}
                onMenuClick={(types) => {
                  switch (types) {
                    case "DELETE":
                      // setShowDeletePopup(true);
                      // track("delete_post", { component: parentType });
                      CommonStore.setShowDownloadAppDrive(true);
                      break;

                    case "MODIFY":
                      goTo("/post/modify", {
                        state: { feedId: feedId, data: null },
                      });
                      // CommonStore.setShowDownloadAppDrive(true);
                      track("edit_post", { component: parentType });
                      break;

                    case "REPORT":
                      getSessionUserFromStorage().then((res) => {
                        if (!res?.nickname) {
                          MainStore.setShowJoinModal(true);
                        } else {
                          openReportBottomSheet(
                            {
                              reportTargetId: String(feedId),
                              reportTargetTypeCode: ReportTargetTypeCode.POST,
                            },
                            () => {
                              if (parentType === "SEARCH_POSTS") {
                                const count = reportPostCount;
                                setReportPostCount(count + 1);
                              }
                              if (parentType === "MISSION_POSTS") {
                                MissionStore.deletePostWithFeedId(feedId);
                              }
                              void popPostFromFeedWithFeedId(feedId);
                              // void initPostMain();
                              void popFromMainList(feedId, [
                                MainFeedTypeCode.POPULAR_POST,
                                MainFeedTypeCode.MAIN_POST,
                                MainFeedTypeCode.MAIN_POST_SINGLE,
                              ]);
                              void popPostFromSearch(feedId);
                              void refreshPopularTag();
                              void popPostFromFeed(itemIndex);
                              handlePostUpdate(
                                feedId,
                                UserActTypeCode.REPORT,
                                sectionIndex,
                                itemIndex
                              );
                              if (onReport) {
                                onReport(feedId);
                              }
                            }
                          );
                        }
                      });
                      break;
                    default:
                      break;
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="post_body"
          aria-label={`Feed body${itemIndexInFetchChunk}`}
        >
          <div className="image_list_wrap" ref={videoViewRef}>
            <Slider
              afterChange={(currentIdx) => setSliderIdx(currentIdx)}
              {...settings}
            >
              {post.fileList &&
                post.fileList.map((item, index) => {
                  const filePath: string = item?.filePath;
                  const thumbnailPath: any = item?.thumbnailFilePath;

                  const sourceUri: string = PUBLIC_BUCKET_URL + filePath;
                  const thumbnailUri: string =
                    PUBLIC_BUCKET_URL + thumbnailPath;
                  const ratioStyle = getRatioStyle(post.fileList);

                  return item?.fileType == "VIDEO" ? (
                    <div
                      key={index}
                      onClick={(event) => {
                        void handleDoubleTap(event);
                      }}
                    >
                      <div>
                        <VideoPlayerWithFallback
                          sourceUri={encodeURI(sourceUri)}
                          defAudioMute={audioMute}
                          onMute={(mute: boolean) => {
                            setAudioMute(mute);
                          }}
                          disabled={!(index === sliderIdx)}
                          carouselIdx={index}
                          wrapperVideoStyle={ratioStyle}
                          thumbnailurl={encodeURI(thumbnailUri)}
                          keystring={
                            sectionIndex == -1
                              ? itemIndex.toString()
                              : sectionIndex.toString()
                          }
                          aria-label={`media content${itemIndexInFetchChunk}-${index}`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      onClick={(event) => {
                        void handleDoubleTap(event);
                      }}
                    >
                      <div>
                        <FastImageWithFallback
                          index={itemIndexInFetchChunk}
                          carouselIdx={index}
                          className="image_item"
                          wrapperStyle={{
                            ...ratioStyle,
                            position: "relative",
                          }}
                          visibleByDefault
                          source={{ uri: thumbnailUri }}
                          fallbackImageUri={sourceUri}
                        />
                      </div>
                    </div>
                  );

                  // return (
                  //   <div
                  //     onClick={(event) => {
                  //       console.log("&&&& 더블클릭 이벤트 자리");
                  //     }}
                  //   >
                  //     <div>
                  //       <div
                  //         className="image_item"
                  //         className="image_item"
                  //         key={index}
                  //       >
                  //         {item?.fileType == "VIDEO" ? (
                  //           <ReactPlayer url={sourceUri} />
                  //         ) : (
                  //           <img src={sourceUri} alt={"logo image " + item} />
                  //         )}
                  //       </div>
                  //     </div>
                  //   </div>
                  // );
                })}
            </Slider>
          </div>
        </div>
        {/* {showPostLike && (
          <div
            className="
              {
                position: "absolute",
                top: postLikeY - 30,
                left: postLikeX - 90,
              } as ViewStyle
            "
          >
            <LottieView
              className="
                {
                  width: 200,
                  height: 200,
                } as ViewStyle
              "
              source={require("../../../assets/animations/postLike_0116.json")}
              autoPlay
              loop={false}
              onAnimationFinish={() => {
                setShowPostLike(false);
              }}
            />
          </div>
        )} */}
        {viewPL && viewPL?.length > 0 && (
          // <ScrollView
          //   horizontal={true}
          //   className="home_app_list"
          //   showsHorizontalScrollIndicator={false}
          // >

          <div className="home_app_list">
            <Slider {...settingHomeAppList}>
              {viewPL.map((product, i) => {
                return (
                  !product.openApiFlag && (
                    <div
                      key={product.productId.toString()}
                      className="home_app_item_wrapper"
                      style={{
                        width:
                          viewPL?.length === 1 || i === viewPL?.length - 1
                            ? widthApp - 10
                            : widthApp * 0.8,
                      }}
                    >
                      <div
                        key={product.productId.toString()}
                        style={{
                          marginLeft: 16,
                          marginRight: i === viewPL.length - 1 ? 6 : 0,
                        }}
                      >
                        <div
                          key={product.productId.toString()}
                          className="home_app_item"
                          onClick={() => {
                            callActHistoryByHistoryType(
                              ActHistoryTypeCode.PRODUCT_READ,
                              ActSectionCode.POST
                            );
                            goTo(`/Product/${product.productId}`);
                          }}
                        >
                          {/* <FastImageWithFallback
                              className="[
                                home_app_img,
                                product.thumbnailYn === "Y" &&
                                  home_app_rep_image,
                              ]}
                              source={{
                                uri: `${PUBLIC_BUCKET_URL}${
                                  product.productFiles[0]?.thumbnailFilePath?.replace(
                                    "ORIGINAL",
                                    "THUMBNAIL"
                                  ) || ""
                                }`,
                              }}
                            /> */}
                          <img
                            className={`home_app_img ${
                              product.thumbnailYn === "Y" &&
                              "home_app_rep_image"
                            }`}
                            // product.thumbnailYn === "Y"
                            //   ? Object.assign(
                            //       home_app_img,
                            //       home_app_rep_image
                            //     )
                            //   : home_app_img
                            src={`${PUBLIC_BUCKET_URL}${
                              product.productFiles[0]?.thumbnailFilePath?.replace(
                                "ORIGINAL",
                                "THUMBNAIL"
                              ) || ""
                            }`}
                            alt={"thumbnailFilePath"}
                          />
                          <div className="home_app_text_wrap">
                            <div className="home_app_text1">
                              {`[${product.brandNameKor}] ${product.productName}`}
                            </div>
                            <div className="home_app_text2">
                              {product.productModelName}
                            </div>
                            <div style={{ display: "flex" }}>
                              {show_up_product_tag === "true" &&
                                product.upYn &&
                                product.upYn == "Y" && (
                                  <div style={{ marginTop: 4, marginRight: 4 }}>
                                    <UpTag />
                                  </div>
                                )}
                              {product.lifecareProductId &&
                                product.lifecareProductId !== null && (
                                  <div className="on_sale_tag_wrap">
                                    <div className="mall_tag">
                                      <MallTag />
                                    </div>
                                    <div className="on_sale_tag_text">
                                      {t("screen.main.label.onSale")}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </Slider>
          </div>
        )}

        <div className="action_wrap">
          <div className="action_left">
            <div className="action_icon_btn">
              <TouchableWithAsyncTask
                onPress={async () => {
                  await onLikeButtonPress(feedId, likestate);
                }}
                authCheckProps={{ enabled: true, authScreenType: "MODAL" }}
              >
                {likestate === "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
              </TouchableWithAsyncTask>
            </div>
            <div className="action_icon_btn">
              <button onClick={() => onCommentButtonPress(feedId)}>
                <ChatIcon />
              </button>
            </div>
            <div className="action_icon_btn">
              <TouchableWithAsyncTask
                onPress={async () => {
                  await onBookmarkButtonPress(feedId, bookmarkYnstate);
                }}
                authCheckProps={{ enabled: true, authScreenType: "MODAL" }}
              >
                {bookmarkYnstate === "Y" ? (
                  <BookmarkOnIcon />
                ) : (
                  <BookmarkOffIcon />
                )}
              </TouchableWithAsyncTask>
            </div>
            <div className="action_icon_btn">
              <button
                onClick={() => {
                  const actHistoryData = {
                    actHistoryTypeCode: ActHistoryTypeCode.POST_SHARE,
                    actPageCode:
                      !sectionIndex || sectionIndex < 0
                        ? ActPageCode.DETAIL_POST_LIST
                        : ActPageCode.MAIN,
                    feedId: (post.feedId ?? 0).toString(),
                    index: globalItemIndex.toString(),
                    likeCount: (likeCountstate ?? 0).toString(),
                    commentCount: (post.commentCount ?? 0).toString(),
                  };
                  const shareInfo: ShareInfo = {
                    title: post?.feedTitle,
                    descriptionText: post?.contents,
                    imageUrl: post.thumbnailFilePath,
                    screen: ShareScreenCode.POST_DETAIL_SCREEN,
                    targetId: post?.feedId,
                    path: `posts/feed/${post?.feedId}`,
                  };
                  void onShare(shareInfo, actHistoryData);

                  track("click_share_button", {
                    content_id: post.feedId.toString(),
                    content_title: post.feedTitle,
                    content_type: FeedTypeCode.POST,
                    like_count: likeCountstate || 0,
                    comment_count: post.commentCount || 0,
                  });
                }}
              >
                <ShareIcon />
              </button>
            </div>
          </div>
          <div className="action_right">
            <button onClick={() => onLikeListButtonPress(feedId)}>
              <div className="action_text like_text">
                {t("screen.popularPost.label.like_label")}{" "}
                <div className="number_text">
                  {likeCountstate ? likeCountstate : 0}
                </div>
              </div>
            </button>
            <button
              onClick={() => onCommentButtonPress(feedId)}
              aria-label={`Move to comment${itemIndexInFetchChunk}`}
            >
              <div className="action_text">
                {t("screen.popularPost.label.comment_label")}{" "}
                <div className="number_text">
                  {post.commentCount ? post.commentCount : 0}
                </div>
              </div>
            </button>
          </div>
        </div>
        {post.feedTitle && (
          <div
            aria-label={`Title${itemIndexInFetchChunk}`}
            className="feed_post_title_text"
          >
            {post.feedTitle}
          </div>
        )}
        {post.feedContentCount > 0 &&
          (post.rating || post.advantage || post.disadvantage) && (
            <ProsAndCons index={itemIndex} {...post} more={false} />
          )}
        {post.feedContentCount > 0 && (
          <div className="contents_wrap">
            {showMore ? (
              <button
                onClick={() => {
                  toggleShowMoreBtn(showMore, post.feedId);
                }}
              >
                <div
                  // className="[contents_text, showMore ? { height: 44 } : {}]""
                  className="contents_text"
                  style={{
                    height: showMore ? 40 : "auto",
                  }}
                >
                  {post.contents
                    // .replace(/(?:\r\n|\r|\n)/g, "")
                    .substring(0, 44)
                    .concat("...")}
                </div>
              </button>
            ) : (
              <div className="entire_contents_text">{post.contents}</div>
            )}

            {showMore && (
              <button
                className="more"
                onClick={() => {
                  toggleShowMoreBtn(showMore as boolean, post.feedId);
                }}
              >
                <div className="more_text">{"more"}</div>
              </button>
            )}
          </div>
        )}

        {!showMore && (
          <div aria-label="Tag list" className="tag_list">
            {post.tagList && renderTags(post.tagList)}
          </div>
        )}

        {post.feedContentCount > 1 && (
          <div className="post_detail_btn_wrap">
            <div
              aria-label="Move to detailed post"
              className="post_detail_btn"
              onClick={() => clickPostDetailBtn(feedId)}
            >
              <div className="post_detail_btn_text">
                {t("screen.popularPost.button.enter_post")}
              </div>
            </div>
          </div>
        )}

        {/* {showDeletePopup && (
          <NotificationModal
            isVisible={showDeletePopup}
            contents1={t("screen.notificationModal.message.deleteMessage")}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => {
              void clickConfirmBtn();
            }}
            onClickExtraButton={closeNotiModal}
            onRequestClose={closeNotiModal}
          />
        )} */}
        {isMainSection && (
          <div className="MainSectionFooter" style={{ height: "60px" }} />
        )}
      </div>
    );
  }
);

// export const feedpostHeight = (post: Post, screenWidth: number) => {
//   const topbottomMargin = 20;
//   let imgH = 0;
//   // if (post && post.fileList.length > 0) {
//   //   imgH = toHeight(
//   //     post.fileList[0].fileResolution ?? "",
//   //     screenWidth - responsiveWidth(4.1) - responsiveWidth(4.1)
//   //   );
//   // }
//   let ratH = 0;
//   if (
//     post.feedContentCount > 0 &&
//     (post.rating || post.advantage || post.disadvantage)
//   ) {
//     ratH = 142;
//   }
//   let productH = 0;
//   if (post.productList && post.productList.length > 0) {
//     productH = 60;
//   }
//   let detailH = 0;
//   if (post.feedContentCount > 1) {
//     detailH = 74;
//   }
//   const hhh = 907 - 477 + imgH - ratH - detailH - productH;
//   const toolh = 24;
//   const titleh = 24;
//   const contexttextH = 44; //

//   let hh = topbottomMargin + 60 + imgH + 10;
//   if (productH > 0) {
//     hh = hh + productH + 10;
//   }
//   hh = hh + toolh + 10 + titleh;
//   if (ratH > 0) {
//     hh = hh + ratH + 6;
//   } else {
//     hh = hh + 6;
//   }

//   hh = hh + contexttextH + detailH + topbottomMargin;
//   return hh;
// };

// export const feedpostHeight2 = async (post: Post, screenWidth: number) => {
//   const calcHeight = async (
//     p: string[],
//     fontsize: number,
//     wid: number,
//     letterSpacing: number
//   ) => {
//     const fontparams2: TSHeightsParams = {
//       text: p,
//       width: wid,
//       fontFamily: undefined,
//       letterSpacing: letterSpacing,
//       fontSize: fontsize,
//     };

//     return await rnTextSize.flatHeights(fontparams2);
//   };

//   const topbottomMargin = 20;
//   let imgH = 0;
//   if (post && post.fileList.length > 0) {
//     const borderimg = screenWidth - responsiveWidth(4.1) - responsiveWidth(4.1);
//     imgH = toHeight(post.fileList[0].fileResolution ?? "", borderimg);
//   }
//   let ratH = 0;
//   if (
//     post.feedContentCount > 0 &&
//     (post.rating || post.advantage || post.disadvantage)
//   ) {
//     ratH = 142 - 22 * 2;
//     const a: number[] = await calcHeight(
//       [post.advantage ?? "", post.disadvantage ?? ""],
//       14,
//       screenWidth - 120,
//       -0.56
//     );
//     const cnt1 =
//       (Math.floor(a[0] / (14 + 2.5)) > 0 ? Math.floor(a[0] / (14 + 2.5)) : 1) *
//       22;
//     const cnt2 =
//       (Math.floor(a[1] / (14 + 2.5)) > 0 ? Math.floor(a[1] / (14 + 2.5)) : 1) *
//       22;
//     ratH = ratH + cnt1 + cnt2;
//   }
//   let productH = 0;
//   if (post.productList && post.productList.length > 0) {
//     productH = 60;
//   }
//   let detailH = 0;
//   if (post.feedContentCount > 1) {
//     detailH = 71;
//   }

//   const toolh = 24;
//   let titleh = 24;
//   const t1 = await calcHeight([post.feedTitle], 16, screenWidth - 40, -0.5);
//   titleh = Math.floor(t1[0] / (16 + 2.5)) * 24;
//   let contexttextH = 22;
//   const a1 = await calcHeight([post.contents], 14, screenWidth - 40 - 30, -0.5);
//   contexttextH = Math.max(2, Math.min(2, Math.floor(a1[0] / (14 + 2.5)))) * 22;

//   let hh = topbottomMargin + 60 + imgH + 10;
//   if (productH > 0) {
//     hh = hh + productH + 10;
//   }
//   hh = hh + toolh + 10 + titleh;
//   if (ratH > 0) {
//     hh = hh + ratH + 6;
//   } else {
//     hh = hh + 6;
//   }

//   hh = hh + contexttextH + detailH + topbottomMargin;

//   return hh;
// };
