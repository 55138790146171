import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";
import PostsListHeader from "../../components/headers/PostsListHeader";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../assets/styles";
import useFeedScreen from "./hook/UseFeedScreen";
import { setPostViewCountUp } from "../../api/post/api";
import { FeedPost } from "./presenter/FeedPost";
import { Post } from "../../api/feed/model";
import { UserActTypeCode } from "../../constants/FeedType.enum";
import MainStore from "../../store/MainStore";
import PostStore from "../../store/PostStore";
import FeedDetailStore from "../../store/FeedDetailStore";
import TagStore from "../../store/TagStore";
import MyHomeStore from "../../store/MyHomeStore";
import { AccountFeedTypeCode } from "../../constants/Account";
import { FeedTypeCode } from "../../constants/Feed";
import SearchStore from "../../store/SearchStore";
// import { handleTopButtonScroll } from "../../utils/common";
// import { goToLifecareProductPage } from "../../utils/mall";
import { ReactComponent as EmptyPost } from "../../assets/images/EmptyPost.svg";
import { useLocation } from "react-router-dom";
import CustomActivityIndicator from "../../components/common/CustomActivityIndicator";
import PostDetailStore from "../../store/PostDetailStore";
import { useWindowScroll } from "react-use";
import { Virtuoso } from "react-virtuoso";
import CommonStore from "../../store/CommonStore";
import "./FeedScreen.scss";

const FeedScreen = observer(() => {
  const {
    postsLists,
    selectedTabCode,
    selectedFeedIndex,
    setSelectedFeedIndex,
    initPostLists,
  } = PostStore;

  const location = useLocation();
  const params = location.state;

  const postDatas = params?.postDatas || postsLists;
  const itemIndex =
    params?.itemIndex > -1 ? params?.itemIndex : selectedFeedIndex;
  const feedParentTypeCode = params?.feedParentTypeCode || selectedTabCode;
  const extraData = params?.extraData;

  const {
    fetchType,
    fetchCursorKey,
    initialFetchedPosts,
    headerTitle,
    pageSize,
    handleQueryMorePosts,
    handleGoHome,
    handleGoBack,
    handleSearch,
  } = useFeedScreen(feedParentTypeCode, extraData);

  const { feedList, setFeedList } = FeedDetailStore;
  const [startLoad, setStartLoad] = useState(false);
  const [visibleStart, setVisibleStart] = useState(false);
  const [isEndData, setIsEndData] = useState(false);
  const [loadInitialData, setLoadInitialData] = useState(false);
  const [status, setStatus] = useState<STATUS>("LOADED");
  const pageSize2 = 10;

  type STATUS = "LOADING" | "LOADED" | "ALL_LOADED";

  const init = useCallback(async () => {
    if (initialFetchedPosts && initialFetchedPosts.length > 0) {
      const clickedPost = initialFetchedPosts[itemIndex];
      clickedPost &&
        clickedPost.feedId &&
        void setPostViewCountUp(clickedPost.feedId);
      setFeedList(initialFetchedPosts as unknown as Post[]);
    } else if (postDatas && postDatas.length > 0) {
      const clickedPost = postDatas[itemIndex];
      clickedPost &&
        clickedPost.feedId &&
        void setPostViewCountUp(clickedPost.feedId);
      setFeedList(postDatas as unknown as Post[]);
    } else {
      await initPostLists();
    }
    setLoadInitialData(true);
    setStartLoad(true);
    setVisibleStart(true);
  }, []);

  useEffect(() => {
    init();
    return () => {
      setStartLoad(false);
    };
  }, []);

  useEffect(() => {
    if (loadInitialData) {
      setFeedList(postDatas as unknown as Post[]);
    }
    setLoadInitialData(false);
  }, [postDatas]);

  const handlePostUpdate = useCallback(
    (
      feedId: number,
      actionType: UserActTypeCode,
      sectionIndex: number,
      itemIndex: number
    ) => {
      if (postsLists) {
        if (
          UserActTypeCode.CANCEL_LIKE === actionType &&
          postDatas[itemIndex].likeYn != "N"
        ) {
          postDatas[itemIndex] = {
            ...postDatas[itemIndex],
            likeCount: ((postDatas[itemIndex].likeCount as number) ?? 1) - 1,
            likeYn: "N",
          };
        } else if (
          UserActTypeCode.SAVE_LIKE === actionType &&
          postDatas[itemIndex].likeYn != "Y"
        ) {
          postDatas[itemIndex] = {
            ...postDatas[itemIndex],
            likeCount: ((postDatas[itemIndex].likeCount as number) ?? 0) + 1,
            likeYn: "Y",
          };
        } else if (UserActTypeCode.CANCEL_BOOKMARK === actionType) {
          postDatas[itemIndex] = { ...postDatas[itemIndex], bookmarkYn: "N" };
        } else if (UserActTypeCode.SAVE_BOOKMARK === actionType) {
          postDatas[itemIndex] = { ...postDatas[itemIndex], bookmarkYn: "Y" };
        }
      } else {
        if (
          UserActTypeCode.CANCEL_LIKE === actionType &&
          initialFetchedPosts[itemIndex].likeYn != "N"
        ) {
          initialFetchedPosts[itemIndex] = {
            ...initialFetchedPosts[itemIndex],
            likeCount:
              ((initialFetchedPosts[itemIndex].likeCount as number) ?? 1) - 1,
            likeYn: "N",
          };
        } else if (
          UserActTypeCode.SAVE_LIKE === actionType &&
          initialFetchedPosts[itemIndex].likeYn != "Y"
        ) {
          initialFetchedPosts[itemIndex] = {
            ...initialFetchedPosts[itemIndex],
            likeCount:
              ((initialFetchedPosts[itemIndex].likeCount as number) ?? 0) + 1,
            likeYn: "Y",
          };
        } else if (UserActTypeCode.CANCEL_BOOKMARK === actionType) {
          initialFetchedPosts[itemIndex] = {
            ...initialFetchedPosts[itemIndex],
            bookmarkYn: "N",
          };
        } else if (UserActTypeCode.SAVE_BOOKMARK === actionType) {
          initialFetchedPosts[itemIndex] = {
            ...initialFetchedPosts[itemIndex],
            bookmarkYn: "Y",
          };
        }
      }
    },
    [postDatas, initialFetchedPosts]
  );

  const onReportDelete = useCallback(
    (feedId: number) => {
      let arr: Post[] = [];
      if (postDatas) {
        arr = postDatas;
      } else {
        if (initialFetchedPosts && initialFetchedPosts.length > 0) {
          arr = initialFetchedPosts as Post[];
        }
      }
      arr = arr.filter((post) => post.feedId !== feedId);
      const newarr = [...feedList!];
      const newarr2 = newarr.filter((post) => post.feedId !== feedId);
      setFeedList(newarr2);
    },
    [feedList, postDatas, initialFetchedPosts, setFeedList]
  );

  const onFollow = useCallback(
    (isFollow: boolean, itemIndex: number) => {
      const newarr = [...feedList!];
      const destuuid = feedList![itemIndex].createMemberUuid;
      newarr.forEach((item, index) => {
        if (item.createMemberUuid === destuuid) {
          newarr[index].followCount = isFollow ? 1 : 0;
        }
      });
      let arr: any[] = [];
      if (postDatas) {
        arr = postDatas;
      } else {
        if (initialFetchedPosts && initialFetchedPosts.length > 0) {
          arr = initialFetchedPosts;
        }
      }

      if (arr.length > 0) {
        arr.forEach((item: any, index) => {
          if (item.createMemberUuid === destuuid) {
            item.followCount = isFollow ? 1 : 0;
          }
        });
      }
      setFeedList(newarr);
    },
    [feedList, postDatas, initialFetchedPosts, setFeedList]
  );

  const handleRender2 = ({ item, index }: { item: Post; index: number }) => {
    return (
      <FeedPost
        parentType={feedParentTypeCode}
        key={item.feedId}
        post={item}
        sectionIndex={-1}
        itemIndex={index}
        // styleWrapper={{ marginTop: index === 0 ? 20 : 60 }}
        updatePost={handlePostUpdate}
        onFollow={onFollow}
        onReport={onReportDelete}
        onDelete={onReportDelete}
        visible={true}
        // lifecareProductId={extraData?.lifecareProductId}
        setInitialIndex={(feedId) => {
          const index = feedList?.findIndex((x) => x.feedId == feedId);
          if (index && index > -1) setSelectedFeedIndex(index);
        }}
      />
    );
  };

  const fetchMoreToTail = useCallback(() => {
    if (status === "LOADED") {
      setStatus("LOADING");
      if (feedList!!.length > 0) {
        const element = feedList!![feedList!!.length - 1];
        const a = element as any;

        if (fetchType === "CURSOR" && fetchCursorKey) {
          let cursor;
          cursor = a[fetchCursorKey];

          const fetchSize = pageSize2;
          if (
            cursor &&
            cursor !== null &&
            ((cursor as number) > 0 || (cursor as string).length > 0)
          ) {
            handleQueryMorePosts({
              cursor: cursor.toString(),
              pageSize: fetchSize,
            })
              ?.then((items: Post[]) => {
                if (items && items.length > 0) {
                  if (initialFetchedPosts && initialFetchedPosts.length > 0) {
                    items.map((x: Post) => initialFetchedPosts.push(x));
                  }
                  setFeedList(feedList!!.concat(items as unknown as Post[]));
                  setIsEndData(false);
                } else {
                  setIsEndData(true);
                }

                setTimeout(() => {
                  setStatus("LOADED");
                }, 500);
              })
              .catch((error) => {
                setIsEndData(true);
                setStatus("LOADED");
              });
          } else {
            setIsEndData(true);
            setStatus("LOADED");
          }
        } else if (fetchType === "OFFSET") {
          let psz = pageSize2;
          if (feedList!!.length % pageSize2 > 0) {
            psz = pageSize;
          }
          const fetchPageIndex = feedList!!.length / psz;
          const fetchSize = psz;

          handleQueryMorePosts({
            pageIndex: fetchPageIndex,
            pageSize: fetchSize,
          })
            ?.then((items: any) => {
              if (items && items.length > 0) {
                if (initialFetchedPosts && initialFetchedPosts.length > 0) {
                  items.map((x: Post) => initialFetchedPosts.push(x));
                }
                setFeedList(feedList!!.concat(items as unknown as Post[]));
                setIsEndData(false);
              } else {
                setIsEndData(true);
              }

              setTimeout(() => {
                setStatus("LOADED");
              }, 500);
            })
            .catch((error) => {
              setIsEndData(true);
              setStatus("LOADED");
            });
        }
      }
    }
  }, [feedList, status, handleQueryMorePosts]);

  // useEffect(() => {
  //   const bottom =
  //     window.scrollY !== 0 &&
  //     Math.ceil(window.innerHeight + window.scrollY) >=
  //       document.documentElement.scrollHeight;

  //   if (bottom) {
  //     fetchMoreToTail();
  //   }
  // }, [y]);

  const renderFooterLoadingIndicator = useCallback(() => {
    if (!isEndData) {
      return (
        <div>
          <CustomActivityIndicator />
        </div>
      );
    } else {
      return (
        <>
          <div style={{ height: "150px" }} />
        </>
      );
    }
  }, [isEndData]);

  return (
    <>
      <div className="post-list-header">
        <PostsListHeader
          title={headerTitle || ""}
          goBack={handleGoBack}
          onSearch={handleSearch}
          isHome={feedParentTypeCode !== "MALL_MAIN_POPULAR_PRODUCT"}
          onClickHome={handleGoHome}
        />
      </div>
      <div style={styles.wrapper} aria-label="Post list screen">
        {feedList && startLoad ? (
          <>
            {visibleStart ? (
              // (
              //   <div ref={itemWrapperRef}>
              //     {feedList.map((item: Post, index: number) => {
              //       return handleRender2({ item, index });
              //     })}
              //     <div style={{ height: "150px" }} />
              //   </div>
              // )
              <>
                <Virtuoso
                  style={{ height: "100vh" }}
                  data={feedList}
                  endReached={fetchMoreToTail}
                  increaseViewportBy={1400}
                  itemContent={(index: number, item: Post) => {
                    return handleRender2({ item, index });
                  }}
                  useWindowScroll
                  initialTopMostItemIndex={itemIndex}
                  components={{
                    Footer: () => <div style={{ height: 60 }}></div>,
                    Item: forwardRef(
                      (
                        { style, children, ...props },
                        ref: React.LegacyRef<HTMLDivElement> | undefined
                      ) => {
                        const space = props["data-index"] === 0 ? 0 : 60;
                        return (
                          <div
                            ref={ref}
                            {...props}
                            style={{
                              ...style,
                              margin: 0,
                            }}
                          >
                            <div
                              style={{
                                height: space,
                                backgroundColor: "transparent",
                              }}
                            />
                            {children}
                          </div>
                        );
                      }
                    ),
                  }}
                />
              </>
            ) : (
              <div style={{ width: "100%", aspectRatio: 390 / 304 }}>
                <EmptyPost
                  width="100%"
                  height="100%"
                  viewBox={"0 0 390 304"}
                ></EmptyPost>
              </div>
            )}
            {renderFooterLoadingIndicator}
          </>
        ) : (
          <div style={{ width: "100%", aspectRatio: 390 / 304 }}>
            <EmptyPost
              width="100%"
              height="100%"
              viewBox={"0 0 390 304"}
            ></EmptyPost>
          </div>
        )}
      </div>
      {/* {extraData?.lifecareProductId && (
        <TouchableWithAuthCheck
          style={[styles.footer_btn, { height: bottomInset + 56 }]}
          onPress={() => goToLifecareProductPage(extraData?.lifecareProductId || 0)}
        >
          <Text style={styles.footer_btn_text}>{t("screen.mall.button.buying")}</Text>
        </TouchableWithAuthCheck>
      )} */}
    </>
  );
});

const styles = {
  header: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_11,
    ...Colors.background_fff,
  },
  wrapper: {
    ...Colors.background_fff,
    flex: 1,
    height: "100%",
  },
  footer_btn: {
    ...Colors.background_682c78,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
};
export default FeedScreen;
