import { memo, useCallback, useState } from "react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import MemberStore from "../../../store/MemberStore";
import {
  RankingPosterFeed,
  RankingPosterResponse,
} from "../../../api/member/model";
import { useTranslation } from "react-i18next";
import classes from "./PostRecommendUserItem.module.scss";
import clsx from "clsx";
import { UserStyleFeedItem } from "../../preference/presenter/UserStyleFeedItem";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import AuthStore from "../../../store/AuthStore";
import FollowerStore from "../../../store/FollowerStore";
import BadgeStore from "../../../store/BadgeStore";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import { BadgeCode } from "../../../constants/Badge.enum";
import { follow, unfollow } from "../../../api/follow/api";

export const PostRecommendUserItem = memo(
  ({ item, index }: { item: RankingPosterResponse; index: number }) => {
    const { t } = useTranslation();

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    const nickname = item.nickname ? item.nickname : "";
    const profileUrl = item.profileUrl;

    const [isFollowing, setIsFollowing] = useState<boolean>(false);

    const moveToAccount = () => {
      goTo(`/user/${nickname}`, {
        state: {
          targetUserId: item.memberUuid,
          nickname: item.nickname,
          profileUrl: item.profileUrl,
          profileBackgroundColor: item.profileBackgroundColor,
        },
        replace: true,
      });
    };

    const createIntroductionText = () => {
      let text = "#" + item.roomScale.trim();
      if (item.tags) {
        item.tags?.slice(0, 2).forEach((tag) => {
          text = text + " #" + tag;
        });
      }
      return text;
    };

    const introductionText = createIntroductionText();

    const onToast = useCallback(
      (follow: boolean) => {
        CommonStore.setToastOption({
          show: true,
          message1: follow
            ? t("screen.myHome.message.followToast", { nickname: nickname })
            : t("screen.myHome.message.unfollowToast", { nickname: nickname }),
        });
      },
      [nickname, t]
    );

    const followUser = useCallback(
      (memberUuid: string) =>
        new Promise((resolve) => {
          if (AuthStore.sessionUser?.memberUuid) {
            CommonStore.callActHistory(
              ActHistoryTypeCode.USER_FOLLOWING,
              memberUuid,
              ActPageCode.POST
            );

            void follow(memberUuid)
              .then((res) => {
                if (res) {
                  onToast(true);
                  FollowerStore.handlePostFollowUpdate(
                    UserActTypeCode.FOLLOW,
                    memberUuid
                  );
                  void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
                  setIsFollowing(true);
                } else {
                  setIsFollowing(false);
                }
                resolve(true);
              })
              .catch((error) => {
                setIsFollowing(false);
                resolve(false);
              });
          } else {
            setIsFollowing(false);
            resolve(false);
          }
        }),
      [onToast]
    );

    const unFollowUser = useCallback(
      (memberUuid: string) =>
        new Promise((resolve) => {
          if (AuthStore.sessionUser?.memberUuid) {
            CommonStore.callActHistory(
              ActHistoryTypeCode.USER_FOLLOWING,
              memberUuid,
              ActPageCode.POST,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              "CANCEL"
            );

            void unfollow(memberUuid)
              .then((res) => {
                if (res) {
                  onToast(false);
                  FollowerStore.handlePostFollowUpdate(
                    UserActTypeCode.UN_FOLLOW,
                    memberUuid
                  );
                  setIsFollowing(false);
                } else {
                  setIsFollowing(true);
                }
                resolve(true);
              })
              .catch((error) => {
                setIsFollowing(true);
                resolve(false);
              });
          } else {
            setIsFollowing(true);
            resolve(false);
          }
        }),
      [onToast]
    );

    const renderItem = useCallback((item: RankingPosterFeed, index: number) => {
      return (
        <div
          className={classes.feed_item_wrapper}
          onClick={() => {
            goTo(`/posts/feed/${item.feedId}`);
          }}
        >
          <UserStyleFeedItem
            item={item}
            index={index}
            like={MemberStore.likeSet.has(
              String(item.feedId) + ":" + String(item.memberId)
            )}
          />
        </div>
      );
    }, []);

    return (
      <>
        <div className={classes.user_style_item_wrapper}>
          <div className={classes.user_style_item_container}>
            <div className={classes.post_header}>
              <div className={classes.post_header_left}>
                <div onClick={moveToAccount}>
                  <FastImageWithFallback
                    className={classes.user_img}
                    source={{ uri: `${PUBLIC_BUCKET_URL}${profileUrl || ""}` }}
                  />
                </div>
                <div
                  className={clsx(classes.info_wrap)}
                  onClick={moveToAccount}
                >
                  <div className={classes.info_wrap_overview}>
                    <span className={classes.info_text1}>{nickname}</span>
                  </div>
                  <span className={classes.info_text3}>{introductionText}</span>
                </div>
                <div className={classes.follow_btn_wrap}>
                  <button
                    className={clsx(
                      isFollowing
                        ? classes.follow_btn_off
                        : classes.follow_btn_on
                    )}
                    onClick={() => {
                      if (isFollowing) {
                        void unFollowUser(item.memberUuid);
                      } else {
                        void followUser(item.memberUuid);
                      }
                    }}
                  >
                    {isFollowing ? (
                      <span className={clsx(classes.follow_text_off)}>
                        {t("screen.followers.button.follwing")}
                      </span>
                    ) : (
                      <span className={clsx(classes.follow_text_on)}>
                        {t("screen.followers.button.follow")}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className={classes.image_wrapper}>
              {item.feeds
                ?.slice(0, 4)
                .map((item, index) => renderItem(item, index))}
            </div>
          </div>
        </div>
      </>
    );
  }
);

PostRecommendUserItem.displayName = "PostRecommendUserItem";
