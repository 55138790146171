import { useCallback, useEffect } from "react";
import { RankingPosterResponse } from "../../../../api/member/model";
import PostStore from "../../../../store/PostStore";
import { observer } from "mobx-react";
import { getRankingPosters } from "../../../../api/member/api";
import { PostRecommendUserItem } from "../PostRecommendUserItem";

const PostRecommendUserWithFollow = observer(() => {
  const { selectedTab, postsLists, setFollowRecommendRankingPosters } =
    PostStore;

  useEffect(() => {
    if (selectedTab === 2 && postsLists.length === 0) {
      void getRankingPosters().then((response) => {
        setFollowRecommendRankingPosters(response);
      });
    }
  }, [selectedTab, postsLists.length, setFollowRecommendRankingPosters]);

  const renderRecommendUsers = useCallback(
    ({ item, index }: { item: RankingPosterResponse; index: number }) => (
      <PostRecommendUserItem item={item} index={index} />
    ),
    []
  );

  return (
    <div style={{ position: "relative", top: -100 }}>
      {PostStore.followRecommendRankingPosters.map((item, index) => {
        return renderRecommendUsers({ item, index });
      })}
    </div>
  );
});

export default PostRecommendUserWithFollow;
