import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { Service, ServicePort } from "../constants/Service";
// @ts-ignore
// import { Platform } from "react-native";
import {
  getSessionUserFromStorage,
  removeSessionUserFromStorage,
} from "../service/Session.service";
// import Toast from "react-native-toast-message";
import { t } from "i18next";
import AuthStore from "../store/AuthStore";
import CommonStore from "../store/CommonStore";

export default interface CommonResponse<T = any> {
  successOrNot: string;
  statusCode: string;
  errorMessage?: string;
  data?: T;
}
let isTokenRefreshing = false;
let refreshSubscribers: any = [];
const onTokenRefreshed = () => {
  refreshSubscribers.map((callback: any) => callback());
};

const addRefreshSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

const no_retry_url = ["/v1/session/autoLogin"];

export const getInstance = (
  serviceName: string,
  isLoading = true,
  showToast?: boolean,
  params?: any
): AxiosInstance => {
  let requestRetryCount = 2;
  let responseRetryCount = 2;
  const retryDelay = 1000;

  if (isLoading) {
    // @ts-ignore
  }

  axios.defaults.headers.post["Content-Type"] = "application/json";
  const baseURL = String(process.env.REACT_APP_API_URL);

  const instance = axios.create({
    baseURL: baseURL,
    params: params ? { ...params } : {},
  });

  const axiosRetry = (errConfig: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(instance(errConfig as AxiosRequestConfig));
      }, retryDelay);
    });
  };

  // 공통 요청 처리
  instance.interceptors.request.use(
    async (
      config: InternalAxiosRequestConfig
    ): Promise<InternalAxiosRequestConfig> => {
      const sessionUser = await getSessionUserFromStorage();
      if (config.headers) {
        //TODO: correlation id 정의 필요
        config.headers["x-correlation-id"] = "12345";
        config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;

        if (sessionUser?.idToken) {
          config.headers["Authorization"] = sessionUser.idToken;
        }
        if (sessionUser?.sessionId) {
          config.headers["x-session-id"] = sessionUser.sessionId;
        }
      }

      return config;
    },
    (error: any): Promise<any> => {
      if (error.config && requestRetryCount) {
        requestRetryCount--;
        return axiosRetry(error.config);
      }
      return Promise.reject("request_rejected");
    }
  );

  // success / error 공통 처리
  /* eslint-disable */
  instance.interceptors.response.use(
    async (response: any): Promise<any> => {
      if (
        response.data.statusCode &&
        response.data.statusCode === "SESSION_EXPIRE"
      ) {
        await removeSessionUserFromStorage();
        AuthStore.setSessionUser(null);
        CommonStore.setLoading(false);
      }
      return response.data;
    },

    async (error: any): Promise<any> => {
      const unknownError: CommonResponse = {
        successOrNot: "N",
        statusCode: "UNKNOWN.ERROR",
        data: {},
      };

      if (
        error.response &&
        error.response.status === 401 &&
        (error.response.data.message === "The incoming token has expired" ||
          error.response.data.message === "Unauthorized" ||
          error.response.data.statusCode === "UNAUTHORIZED")
      ) {
        const originalRequest = error.config;
        if (isTokenRefreshing) {
          return new Promise((resolve) => {
            addRefreshSubscriber(() => {
              resolve(instance(originalRequest));
            });
          });
        }
        if (!isTokenRefreshing) {
          refreshSubscribers = [];
          isTokenRefreshing = true;
          const { tryAutoLogin } = AuthStore;

          const refreshTokenResult = await tryAutoLogin();

          if (refreshTokenResult) {
            isTokenRefreshing = false;
            onTokenRefreshed();
            return instance(originalRequest);
          } else {
            refreshSubscribers = [];
            isTokenRefreshing = false;
          }
        }
      }

      //타임아웃은 retry 안함
      if (
        !error.toString().includes("timeout") &&
        !no_retry_url.includes(error.config.url)
      ) {
        if (error.config && responseRetryCount) {
          responseRetryCount--;
          return axiosRetry(error.config);
        }
      }

      if (!showToast) {
        return unknownError;
      }

      if (
        error.response &&
        error.response.status.toString().indexOf("40") === 0
      ) {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError") + " ",
        });
        CommonStore.setLoading(false);
      } else {
        if (error.toString().includes("timeout")) {
          CommonStore.setToastOption({
            show: true,
            message1: t("common.message.unknownException") + ":01" + " ",
          });
        } else if (error.toString().includes("request_rejected")) {
          CommonStore.setToastOption({
            show: true,
            message1: t("common.message.unknownException") + ":02" + " ",
          });
        } else if (error.toString().includes("Network Error")) {
          CommonStore.setToastOption({
            show: true,
            message1: t("common.message.unknownException") + ":03" + " ",
          });
        } else {
          CommonStore.setToastOption({
            show: true,
            message1: t("common.message.unknownException") + ":04" + " ",
          });
        }
        CommonStore.setLoading(false);
      }

      return unknownError;
    }
  );

  return instance;
};
