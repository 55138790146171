import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import {
  getRecentFeedList,
  getRecommendFeedList,
  getProductFeedList,
  getRecentFeedDetailList,
  getPopularFeedDetailList,
  getFilteredFeedList,
  getFeedFilter,
} from "../api/feed/api";
import { Post } from "../api/feed/model";
import { FeedTypeCode, PostMainTypeCode } from "../constants/Feed";
import { UserActTypeCode } from "../constants/FeedType.enum";
import AuthStore from "./AuthStore";
import { FilterParam } from "../api/feed/model";
import { FilterGroupName } from "../constants/post/Filter";
import { FeedParentTypeCode } from "../screens/feed/hook/UseFeedScreen";
import { UserItem } from "../components/common/HorizontalUserList";
import { getAccountFullFeed } from "../api/account/api";
import { getRecommendedFollowings } from "../api/follow/api";
import { RankingPosterResponse } from "../api/member/model";
interface filePathType {
  idx: number;
  path: string;
}
interface FilterList {
  groupCode: string;
  codeValue: string;
  codeName: string;
  sortOrder: string;
  subFilterList: SubFilterList[];
}
export interface SubFilterList {
  groupCode: string;
  codeValue: string;
  codeName: string;
  sortOrder: string;
}
interface PostFilterInfo {
  isShow: boolean;
  filterCode: string;
}
interface FilterQuery {
  [key: string]: string;
}

type TAB_TYPE = "POPULAR" | "RECENT" | "FOLLOWING";

const INITIAL_VALUE = {
  _loading: true,
  _isLoadingMore: false,
  _canLoadMore: true,
  _postsLists: [],
  _pageIndexByTab: new Map<PostMainTypeCode, number>([
    [PostMainTypeCode.POPULAR, 0],
    [PostMainTypeCode.RECENT, 0],
    [PostMainTypeCode.FOLLOWING, 0],
  ]),
  _selectedBookmarkFeeds: new Map<PostMainTypeCode, Set<number>>(),
  _selectedTabType: "POPULAR" as TAB_TYPE,
};

class PostStore {
  constructor() {
    makeObservable(this);
  }

  @observable _loading = INITIAL_VALUE._loading;
  @observable _isLoadingMore = INITIAL_VALUE._isLoadingMore;
  @observable _canLoadMore = INITIAL_VALUE._canLoadMore;
  @observable _postsLists: Post[] = INITIAL_VALUE._postsLists;
  @observable _pageIndexByTab: Map<PostMainTypeCode, number> =
    INITIAL_VALUE._pageIndexByTab;
  @observable _selectedTab = 0; // 0 : 인기, 1 : 최신, 2 : 팔로잉
  @observable _selectedTabType: TAB_TYPE = INITIAL_VALUE._selectedTabType;
  @observable _selectedTabCode: FeedParentTypeCode = "POSTTAB_POPULAR_POSTS";
  @observable _pageIndex = 0;
  @observable _isDeepLink = false;
  @observable _filterList: FilterList[] | undefined;
  @observable _subFilterMap: Map<string, string> = new Map<string, string>();
  @observable _followRecommendRankingPosters: RankingPosterResponse[] = [];
  @observable _followingTabUserItemList: UserItem[] = [];
  @observable _followingTabSelectedMemberUuid: string | undefined;

  // 필터 노출 여부
  @observable postFilterInfo: PostFilterInfo = {
    isShow: false,
    filterCode: "", // 빈값 = 전체 필터코드 리스트 , "선택한필터코드" = 선택한 필터코드의 서브필터리스트
  };
  // 선택한 필터 {filterCode : subFilterCode}
  @observable _filterQuery: FilterQuery = {};
  @observable _selectedFeedIndex: number = 0;

  @computed get selectedTabCode() {
    return this._selectedTabCode;
  }

  @computed get selectedTabType() {
    return this._selectedTabType;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get isLoadingMore() {
    return this._isLoadingMore;
  }

  @computed get canLoadMore() {
    return this._canLoadMore;
  }

  @computed get postsLists() {
    return this._postsLists;
  }

  @computed get selectedTab() {
    return this._selectedTab;
  }

  @computed get pageIndex() {
    return this._pageIndex;
  }

  @computed get isDeepLink() {
    return this._isDeepLink;
  }

  @computed get filterList() {
    return this._filterList;
  }

  @computed get filterQuery() {
    return this._filterQuery;
  }

  @computed get hasFilterOption() {
    return (
      this._filterQuery !== undefined &&
      Object.keys(this._filterQuery).length > 0
    );
  }

  @computed get subFilterMap() {
    return this._subFilterMap;
  }

  @computed get selectedFeedIndex() {
    return this._selectedFeedIndex;
  }

  @computed get followRecommendRankingPosters() {
    return this._followRecommendRankingPosters;
  }

  @computed get followingTabUserItemList() {
    return this._followingTabUserItemList;
  }
  @computed get followingTabSelectedMemberUuid() {
    return this._followingTabSelectedMemberUuid;
  }

  @action setFollowingTabUserItemList = (x: UserItem[]) => {
    this._followingTabUserItemList = x;
  };

  @action setFollowingTabSelectedMemberUuid = (x: string | undefined) => {
    this._followingTabSelectedMemberUuid = x;
  };

  @action setFollowRecommendRankingPosters = (
    rankingPosterResponse: RankingPosterResponse[]
  ) => {
    const userMemberUuid = AuthStore.sessionUser?.memberUuid;
    // 포스트 랭킹 유저 20명 중 자신이 포함될 경우 제거
    if (userMemberUuid) {
      this._followRecommendRankingPosters = rankingPosterResponse.filter(
        (poster: RankingPosterResponse) => {
          return poster.memberUuid !== userMemberUuid;
        }
      );
    } else {
      this._followRecommendRankingPosters = rankingPosterResponse;
    }
  };

  @action setPageIndex = (index: number) => {
    this._pageIndex = index;
  };

  @action initPostMain = async () => {
    this.clear();

    const pageSize = 10;
    const pageIndex = 0;
    const feedTypeCode = "POST";
    const followingYn = "N";

    const res = await getRecentFeedList(
      pageSize,
      pageIndex,
      feedTypeCode,
      followingYn
    );

    if (res.successOrNot == "Y") {
      const postLists: Post[] = res.data;
      this.setPostLists(postLists);

      if (postLists.length > 4) {
        this.setIsLoadingMore(false);
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // error
    }
    this.setLoading(false);
  };

  @action setSelectedTab = (tabIdx: number) => {
    this._selectedTab = tabIdx;

    if (tabIdx === 1) {
      this.setSelectedTabCode("POSTTAB_RECENT_POSTS");
      this.setSelectedTabType("RECENT");
    } else if (tabIdx === 2) {
      this.setSelectedTabCode("POSTTAB_FOLLWING_POSTS");
      this.setSelectedTabType("FOLLOWING");
    } else {
      // Default
      this.setSelectedTabCode("POSTTAB_POPULAR_POSTS");
      this.setSelectedTabType("POPULAR");
    }
  };

  @action setSelectedTabCode = (code: FeedParentTypeCode) => {
    this._selectedTabCode = code;
  };

  @action setSelectedTabType = (type: TAB_TYPE) => {
    this._selectedTabType = type;
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setIsLoadingMore = (loading: boolean) => {
    this._isLoadingMore = loading;
  };

  @action setCanLoadMore = (val: boolean) => {
    this._canLoadMore = val;
  };

  @action setPostLists = (posts: Post[]) => {
    this._postsLists = posts;
  };

  @action setIsDeepLink = (type: boolean) => {
    this._isDeepLink = type;
  };

  @action setPost = (post: Post, index: number) => {
    this._postsLists.splice(index, 1, post);
  };

  @action setPostWithFeedId = (newPost: Post, feedId: number) => {
    const matchedIndex = this.findMatchedPostIndex(feedId);

    if (matchedIndex != -1) {
      this._postsLists[matchedIndex] = newPost;
    }
  };

  @action setSelectedFeedIndex = (index: number) => {
    this._selectedFeedIndex = index;
  };

  @action clear = () => {
    this._loading = INITIAL_VALUE._loading;
    this._isLoadingMore = INITIAL_VALUE._isLoadingMore;
    this._canLoadMore = INITIAL_VALUE._canLoadMore;
    this._postsLists = INITIAL_VALUE._postsLists;
    this._selectedFeedIndex = 0;
    this._selectedTab = 0;
    this.clearFollowingTab();
  };

  @action clearFollowingTab = () => {
    this._followingTabSelectedMemberUuid = undefined;
    this._followingTabUserItemList = [];
    this._postsLists = [];
  };

  @action clearExceptPosts = () => {
    this._isLoadingMore = INITIAL_VALUE._isLoadingMore;
    this._canLoadMore = INITIAL_VALUE._canLoadMore;
  };

  @action getPageIndexByType = (type: PostMainTypeCode) => {
    return this._pageIndexByTab.get(type);
  };

  @action getRecommendFeed = async (
    pageSize: number,
    pageIndex: number,
    feedTypeCode: string,
    followingYn: string,
    memberUuid: string
  ) => {
    this.clear();

    const res = await getRecommendFeedList(
      pageSize,
      pageIndex,
      feedTypeCode,
      followingYn,
      memberUuid
    );

    if (res.successOrNot == "Y") {
      // const postLists: Post[] = res.data;
      const postLists: Post[] = [];

      this.setPostLists(postLists);

      if (postLists.length > 0) {
        this.setIsLoadingMore(false);
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // error
    }
    this.setLoading(false);
  };

  @action getRecentFeed = async (
    pageSize: number,
    pageIndex: number,
    feedTypeCode: string,
    followingYn: string
  ) => {
    this.clear();

    const res = await getRecentFeedList(
      pageSize,
      pageIndex,
      feedTypeCode,
      followingYn
    );

    if (res.successOrNot == "Y") {
      const postLists: Post[] = res.data;
      this.setPostLists(postLists);

      if (postLists.length > 0) {
        this.setIsLoadingMore(false);

        if (pageSize > postLists.length) {
          this.setCanLoadMore(false);
        }
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // error
    }
    this.setLoading(false);
  };

  @action getProductFeedList = async (
    pageSize: number,
    pageIndex: number,
    productFeedSearchType: string,
    productId: number
  ) => {
    this.clear();

    const res = await getProductFeedList(
      pageSize,
      pageIndex,
      productFeedSearchType,
      productId
    );

    if (res.successOrNot == "Y") {
      const postLists: Post[] = res.data;
      this.setPostLists(postLists);

      if (postLists.length > 0) {
        this.setIsLoadingMore(false);
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // error
    }
    this.setLoading(false);
  };

  @action getMoreRecommendFeedList = async (
    pageSize: number,
    pageIndex: number,
    feedTypeCode: string,
    followingYn: string,
    memberUuid: string
  ) => {
    const res = await getRecommendFeedList(
      pageSize,
      pageIndex,
      feedTypeCode,
      followingYn,
      memberUuid
    );
    const formerPostsLength = this._postsLists.length;

    if (res.successOrNot == "Y") {
      const newPostLists: Post[] = res.data;

      this.addPostsLists(newPostLists);

      if (newPostLists.length > 0) {
        this.setIsLoadingMore(false);
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // setAlertMessage(t("common.message.networkError"));
    }
  };

  @action getMoreRecentFeedList = async (
    pageSize: number,
    start: number,
    feedTypeCode: string,
    followingYn: string
  ) => {
    const res = await getRecentFeedList(
      pageSize,
      start,
      feedTypeCode,
      followingYn
    );
    const formerPostsLength = this._postsLists.length;

    if (res.successOrNot == "Y") {
      const newPostLists: Post[] = res.data;

      this.addPostsLists(newPostLists);

      if (newPostLists.length > 0) {
        this.setIsLoadingMore(false);
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // setAlertMessage(t("common.message.networkError"));
    }
  };

  @action getMoreProductFeedList = async (
    pageSize: number,
    pageIndex: number,
    productFeedSearchType: string,
    productId: number
  ) => {
    const res = await getProductFeedList(
      pageSize,
      pageIndex,
      productFeedSearchType,
      productId
    );
    const formerPostsLength = this._postsLists.length;

    if (res.successOrNot == "Y") {
      const newPostLists: Post[] = res.data;

      this.addPostsLists(newPostLists);

      if (newPostLists.length > 0) {
        this.setIsLoadingMore(false);
      } else {
        this.setCanLoadMore(false);
      }
    } else {
      // setAlertMessage(t("common.message.networkError"));
    }
  };

  @action getRecentFeedDetailList = async (
    pageSize: number,
    cursor: string | null = null,
    feedTypeCode: string,
    followingYn: string
  ) => {
    const isFirstPage = cursor === null;
    const recentFeedDetail = await getRecentFeedDetailList(
      pageSize,
      cursor,
      feedTypeCode,
      followingYn
    );

    runInAction(() => {
      if (recentFeedDetail.successOrNot == "Y") {
        const postLists: Post[] = recentFeedDetail.data.feedFindVOList ?? [];

        if (isFirstPage) {
          this.setPostLists(postLists);
        } else {
          this.addPostsLists(postLists);
        }

        this.setIsLoadingMore(postLists.length == 0);
        //NOTE: 마지막 페이지인 경우
        this.setCanLoadMore(pageSize <= postLists.length);
      }
      this.setLoading(false);
    });
  };

  @action getPopularFeedDetailList = async (
    pageSize: number,
    pageIndex: number
  ) => {
    const isFirstPage = pageIndex === 0;
    const popularFeedDetail = await getPopularFeedDetailList(
      pageSize,
      pageIndex
    );

    runInAction(() => {
      if (popularFeedDetail.successOrNot == "Y") {
        const postLists: Post[] = popularFeedDetail.data.feedFindVOList;

        isFirstPage
          ? this.setPostLists(postLists)
          : this.addPostsLists(postLists);

        if (postLists.length > 0) {
          this.setIsLoadingMore(false);

          if (pageSize > postLists.length) {
            //NOTE: 마지막 페이지인 경우
            this.setCanLoadMore(false);
          }
        } else {
          this.setCanLoadMore(false);
        }
      }
      this.setLoading(false);
    });
  };

  @action addPostsLists = (newPostLists: Post[]) => {
    this._postsLists.push(...newPostLists);
  };

  @action findMatchedPostIndex = (feedId: number) => {
    for (let postIndex = 0; postIndex < this._postsLists.length; postIndex++) {
      if (this._postsLists[postIndex].feedId === feedId) {
        return postIndex;
      }
    }
    return -1;
  };

  @action updatePostStoreList = (
    action: UserActTypeCode,
    feedId: number,
    extraData?: any
  ) => {
    const matchedIndex = this.findMatchedPostIndex(feedId);

    if (matchedIndex === -1) return;

    switch (action) {
      case UserActTypeCode.CANCEL_LIKE:
        if (this._postsLists[matchedIndex]) {
          const post = { ...this._postsLists[matchedIndex] };
          const formerCount: number = post.likeCount;

          post.likeYn = "N";
          post.likeCount = formerCount - 1;

          this.setPost(post, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
        if (this._postsLists[matchedIndex]) {
          const post = { ...this._postsLists[matchedIndex] };
          const formerCount: number = post.likeCount;

          post.likeYn = "Y";
          post.likeCount = formerCount + 1;

          this.setPost(post, matchedIndex);
        }
        break;
      case UserActTypeCode.CANCEL_BOOKMARK:
        if (this._postsLists[matchedIndex]) {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            bookmarkYn: "N",
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_BOOKMARK:
        if (this._postsLists[matchedIndex]) {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            bookmarkYn: "Y",
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SHOW_MORE:
        if (
          this._postsLists[matchedIndex] &&
          this._postsLists[matchedIndex].showMore === true
        ) {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            showMore: false,
          };
          this.setPost(updatedPost, matchedIndex);
        } else {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            showMore: true,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.OPEN_MODAL:
        if (this._postsLists[matchedIndex]) {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            showDeletePopup: true,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CLOSE_MODAL:
        if (this._postsLists[matchedIndex]) {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            showDeletePopup: false,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.POP:
      case UserActTypeCode.REPORT:
      case UserActTypeCode.DELETE:
        if (this._postsLists[matchedIndex]) {
          this._postsLists.splice(matchedIndex, 1);
        }
        break;

      case UserActTypeCode.UPDATE_COMMENT:
        if (this._postsLists[matchedIndex]) {
          const updatedPost = {
            ...this._postsLists[matchedIndex],
            commentCount: extraData.commentCount,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
    }
  };

  @action popPostFromFeedWithFeedId = (feedId: number) => {
    this._postsLists.forEach((elem, index) => {
      // TODO 에러가 나!
      // elem.feedId === feedId ? this._postsLists.splice(index, 1) : "";
    });
  };

  @action initPostLists = async () => {
    const pageSize = 10;
    const feedTypeCode = "POST";
    this.clearLoading();
    this.postFilterReset();
    this.setLoading(true);

    if (this._selectedTab === 1) {
      await this.getRecentFeedDetailList(pageSize, null, feedTypeCode, "N");
    } else if (this._selectedTab === 2) {
      await this.getRecentFeedDetailList(pageSize, null, feedTypeCode, "Y");
    } else {
      //Default
      await this.getPopularFeedDetailList(pageSize, 0);
    }

    this.setPageIndex(0);
    this.setLoading(false);
  };

  @action updatePost = (feedId: number, post: Post) => {
    const index = this._postsLists.findIndex((x) => x.feedId == feedId);

    if (index != -1) {
      this._postsLists.splice(index, 1, post);
    }
  };

  @action getFeedFilter = async () => {
    const result = await getFeedFilter();
    if (result.successOrNot == "Y") {
      this._filterList = result.data;

      // subFilter 한글명 조회 성능 개선을 위해 사전에 map 으로 셋업
      this._filterList?.forEach((item) => {
        item.subFilterList.forEach((sub) => {
          this._subFilterMap?.set(
            item.codeValue + "_" + sub.codeValue,
            sub.codeName
          );
        });
      });
    }
  };

  @action postFilterReset = () => {
    this._filterQuery = {};
    void this.initPostsByFilter();
  };

  @action deleteFilterOption = (key: string) => {
    delete this.filterQuery[key];
    this._filterQuery = toJS(this.filterQuery);
    void this.initPostsByFilter();
  };

  @action setPostFilterInfo = (isShow: boolean, filterCode?: string) => {
    this.postFilterInfo.isShow = isShow;
    this.postFilterInfo.filterCode =
      filterCode && filterCode.length > 0 ? filterCode : "";
  };

  @action setFilterQuery = (filterCode: string, subFilterCode: string) => {
    this.filterQuery[filterCode] = subFilterCode;
    void this.initPostsByFilter();
  };

  @action initPostsByFilter = async (clearData?: boolean) => {
    clearData && this.clearFollowingTab();
    this.clearLoading();

    const tabIdx = this.selectedTab;
    this.setIsDeepLink(false);
    this.setPageIndex(0);

    const pageSize = 10;
    const pageIndex = 0;

    //인기 리스트
    if (tabIdx === 0) {
      if (this.hasFilterOption) {
        await this.findPostsByFilter();
      } else {
        await this.getPopularFeedDetailList(pageSize, pageIndex);
      }
    }
    //최신 리스트
    if (tabIdx === 1) {
      if (this.hasFilterOption) {
        await this.findPostsByFilter();
      } else {
        await this.getRecentFeedDetailList(pageSize, null, "POST", "N");
      }
    }
    //팔로잉 리스트
    if (
      tabIdx === 2 &&
      AuthStore.sessionUser?.nickname &&
      AuthStore.sessionUser.nickname.length > 0
    ) {
      const res = await getRecommendedFollowings();
      if (res !== null) this.setFollowingTabUserItemList(res);
      else this.setFollowingTabUserItemList([]);
      await this.getRecentFeedDetailList(pageSize, null, "POST", "Y");
    }

    if (this.loading) {
      this.setLoading(false);
    }
  };

  @action findPostsByFilter = async () => {
    const filterParam = this.createFilterParam();
    filterParam.filterQuery = toJS(this.filterQuery); //참조값으로 전달하지 않기 위함;

    const recentFeedDetail = await getFilteredFeedList(filterParam);
    const isFirstPage = filterParam.page === 0;

    if (recentFeedDetail && recentFeedDetail.successOrNot == "Y") {
      const postLists: Post[] = recentFeedDetail.data.feedFindVOList ?? [];

      if (!this.isFilterOptionEquals(filterParam)) {
        return;
      }

      if (isFirstPage) {
        this.setPostLists(postLists);
      } else {
        this.addPostsLists(postLists);
      }

      this.setIsLoadingMore(postLists.length == 0);
      //NOTE: 마지막 페이지인 경우
      this.setCanLoadMore(filterParam.pageSize <= postLists.length);
    }
  };

  //필터없는 기존 로직.
  @action findPostsByNoFilter = async () => {
    const PAGE_SIZE = 10;
    const selectedTab = this.selectedTab;
    const postsLists = this.postsLists;
    const pageIndex = this.pageIndex;

    const feedId =
      postsLists && postsLists.length > 0
        ? `${postsLists[postsLists?.length - 1].feedId}`
        : null;

    if (selectedTab === 0) {
      await this.getPopularFeedDetailList(PAGE_SIZE, pageIndex);
    }
    if (selectedTab === 1) {
      await this.getRecentFeedDetailList(
        PAGE_SIZE,
        feedId,
        FeedTypeCode.POST,
        "N"
      );
    }
    if (selectedTab === 2) {
      if (this.followingTabSelectedMemberUuid) {
        const res = await getAccountFullFeed({
          memberUuid: this.followingTabSelectedMemberUuid,
          blocked: "N",
          pageSize: PAGE_SIZE,
          pageIndex: pageIndex,
        });
        if (res) {
          this.addPostsLists(res.list as Post[]);
          this.setIsLoadingMore(res.list.length == 0);
          this.setCanLoadMore(PAGE_SIZE <= res.list.length);
        }
      } else {
        await this.getRecentFeedDetailList(
          PAGE_SIZE,
          feedId,
          FeedTypeCode.POST,
          "Y"
        );
      }
    }
  };

  @action clearLoading = () => {
    this._loading = INITIAL_VALUE._loading;
    this._isLoadingMore = INITIAL_VALUE._isLoadingMore;
    this._canLoadMore = INITIAL_VALUE._canLoadMore;
  };

  createFilterParam = () => {
    const filterParam = {} as FilterParam;
    filterParam.pageSize = 10;
    filterParam.page = this.pageIndex;
    filterParam.direction = "forward";
    filterParam.sort = this.selectedTab === 1 ? "dateDESC" : "popularityDESC";
    filterParam.feedTypeCode = "POST";
    if (AuthStore.sessionUser?.memberUuid) {
      filterParam.memberUuid = AuthStore.sessionUser?.memberUuid;
    }
    if (AuthStore.tempMember?.tempMemberUUID) {
      filterParam.tempMemberUuid = AuthStore.tempMember?.tempMemberUUID;
    }
    filterParam.filterQuery = {
      CATEGORY1: "",
      LIFE_STYLE: "",
      SPACE: "",
      THEME: "",
    };

    return filterParam;
  };

  createRecentFilterParam = (page: number, pageSize: number) => {
    const filterParam = {} as FilterParam;
    filterParam.pageSize = pageSize;
    filterParam.page = page;
    filterParam.direction = "forward";
    filterParam.sort = "dateDESC";
    filterParam.feedTypeCode = "POST";
    if (AuthStore.sessionUser?.memberUuid) {
      filterParam.memberUuid = AuthStore.sessionUser?.memberUuid;
    }
    if (AuthStore.tempMember?.tempMemberUUID) {
      filterParam.tempMemberUuid = AuthStore.tempMember?.tempMemberUUID;
    }
    filterParam.filterQuery = this.filterQuery;
    return filterParam;
  };

  createPopularFilterParam = (page: number, pageSize: number) => {
    const filterParam = {} as FilterParam;
    filterParam.pageSize = pageSize;
    filterParam.page = page;
    filterParam.direction = "forward";
    filterParam.sort = "popularityDESC";
    filterParam.feedTypeCode = "POST";
    if (AuthStore.sessionUser?.memberUuid) {
      filterParam.memberUuid = AuthStore.sessionUser?.memberUuid;
    }
    if (AuthStore.tempMember?.tempMemberUUID) {
      filterParam.tempMemberUuid = AuthStore.tempMember?.tempMemberUUID;
    }
    filterParam.filterQuery = this.filterQuery;
    return filterParam;
  };

  isFilterOptionEquals = (filterParam: FilterParam) => {
    return (
      filterParam.filterQuery[FilterGroupName.CATEGORY1] ==
        this.filterQuery[FilterGroupName.CATEGORY1] &&
      filterParam.filterQuery[FilterGroupName.THEME] ==
        this.filterQuery[FilterGroupName.THEME] &&
      filterParam.filterQuery[FilterGroupName.LIFE_STYLE] ==
        this.filterQuery[FilterGroupName.LIFE_STYLE] &&
      filterParam.filterQuery[FilterGroupName.SPACE] ==
        this.filterQuery[FilterGroupName.SPACE]
    );
  };
}

export default new PostStore();
