import React, { useCallback, useEffect, useState } from "react";
import AccountMain from "./presenter/AccountMain";
import AuthStore from "../../store/AuthStore";
import { observer } from "mobx-react";
import { MemberInfoResponse } from "../../api/member/model";
import {
  getMemberByNickname,
  getMemberInfoResponse,
} from "../../api/member/api";
import MyHomeStore from "../../store/MyHomeStore";
import { useLocation, useParams } from "react-router-dom";
import AccountStore from "../../store/AccountStore";
import { AccountFeedTypeCode } from "../../constants/Account";
import { NotificationModal } from "../../components/modals";
import { t } from "i18next";
// import { goBack } from "../../navigation/NavigationFunction";
import { StatusCode } from "../../constants/Common.enum";
import { goBack } from "../../hooks/navigate/NavigateFunction";
import { CustomActivityIndicator } from "../../components/common";
// import MyAppliancesStore from "../../store/MyAppliancesStore";

const AccountScreen = observer(() => {
  const location = useLocation();
  const params = location.state;
  const [accountInfo, setAccountInfo] = useState({} as MemberInfoResponse);
  const [accountStore, setAccountStore] = useState<AccountStore>();
  const [isShowBlockedModal, setIsShowBlockedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { initAccountStore, clearAccountStore } = MyHomeStore;
  const [modalButtonName, setModalButtonName] = useState<string>(
    t("common.label.check")
  );
  const [isFocused, setIsFocused] = useState(!document.hidden);
  const [initialActiveTab, setInitialActiveTab] = useState(
    params?.initialActiveTab || AccountFeedTypeCode.POST
  );
  const [userId, setUserId] = useState("");
  const isMy =
    location.pathname.toUpperCase() === "/MYHOME" ||
    AuthStore.sessionUser?.nickname === useParams().nickname;

  const nickname = isMy
    ? AuthStore.sessionUser?.nickname
    : useParams().nickname;

  const getAccountInfo = useCallback(async () => {
    setInitialActiveTab(AccountFeedTypeCode.POST);
    let uuid = "";
    if (isMy) {
      uuid = AuthStore.sessionUser?.memberUuid || "";
    } else {
      const res = await getMemberByNickname(nickname || "");
      uuid = res !== null ? res.memberUuid : "";
    }
    setUserId(uuid);

    const memberInfoResponse = await getMemberInfoResponse(uuid);
    if (
      !(
        memberInfoResponse.successOrNot === "N" &&
        memberInfoResponse.statusCode === StatusCode.UNKNOWN_ERROR
      )
    ) {
      const memberInfo = (
        memberInfoResponse?.successOrNot === "Y"
          ? memberInfoResponse?.data
          : null
      ) as MemberInfoResponse;

      if (memberInfo) {
        setAccountInfo(memberInfo);
        setIsShowBlockedModal(false);
      } else {
        setIsShowBlockedModal(true);
      }
    }
  }, [isMy, nickname]);

  // for account post, qna, ab
  useEffect(() => {
    const fetchData = async (userId: string) => {
      const accountStore = await initAccountStore(userId);

      setAccountStore(accountStore);
    };

    void fetchData(userId);
  }, [userId, initAccountStore]);

  useEffect(() => {
    if (isFocused) {
      setIsLoading(true);
      void getAccountInfo().finally(() => setIsLoading(false));
    }
  }, [isFocused, getAccountInfo]);

  // useEffect(() => {
  //   try {
  //     const routes = navigation.getState().routes;
  //     if (routes && routes.length > 0 && routes[0].state?.history) {
  //       const history = routes[0].state?.history as Array<{ key: string; type: string }>;
  //       if (history && history.length > 0 && history[history.length - 1].key.includes("HomeStack")) {
  //         setModalButtonName(t("common.label.gotoHome"));
  //       }
  //     } else if (!routes[0].state) {
  //       setModalButtonName(t("common.label.gotoHome"));
  //     }
  //   } catch (e) {
  //     //
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigation]);

  // const checkIsClear = useCallback(() => {
  //   const routes = navigation.getState().routes;
  //   const userHomeRoutes = routes.filter(
  //     (route) =>
  //       (route.name == "UserHomeScreen" &&
  //         route.params?.targetUserId === data?.route?.params?.targetUserId) ||
  //       route.name == "MyHomeScreen"
  //   );
  //   const isMyHome = data.route.name === "UserHomeScreen" && isMy;

  //   return userHomeRoutes.length === 0 && !isMyHome;
  // }, [data, navigation, isMy]);

  // useEffect(() => {
  //   clearAccountStore(userId);
  // }, [userId, clearAccountStore]);

  return (
    <>
      <div
        style={{
          backgroundColor: accountInfo?.profileBackgroundColor ?? "#FFF",
        }}
        aria-label="Account screen"
      />
      {isShowBlockedModal && !isLoading ? (
        <NotificationModal
          isVisible={isShowBlockedModal}
          contents1={t("common.message.blockedOrDeletedMember")}
          onRequestClose={() => {
            goBack();
            setIsShowBlockedModal(false);
          }}
          defaultButtonText={modalButtonName}
        />
      ) : (
        <AccountMain
          isMy={isMy}
          userId={userId}
          nickname={nickname}
          profileUrl={accountInfo.profileUrl}
          profileBackgroundColor={accountInfo.profileBackgroundColor}
          accountInfo={accountInfo}
          // accountStore={accountStore}
          initialActiveTab={initialActiveTab}
          goBackOnClickHome={!!params?.goBackOnClickHome}
          isLoading={isLoading}
        />
      )}
    </>
  );
});

export default AccountScreen;
