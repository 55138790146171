import React, { ReactElement, useCallback, useMemo, useState } from "react";
import PostStore from "../../../../store/PostStore";
import { observer } from "mobx-react";
import { ReactComponent as ArrowDownOff } from "../../../../assets/icons/post/post_arrow_off.svg";
import { ReactComponent as ArrowDownOn } from "../../../../assets/icons/post/post_arrow_on.svg";
import { ReactComponent as Close } from "../../../../assets/icons/post/close_filter.svg";
import { ReactComponent as CloseToolTip } from "../../../../assets/icons/post/close_white.svg";
import { ReactComponent as FilterOff } from "../../../../assets/icons/post/post_filter_off.svg";
import { ReactComponent as FilterOn } from "../../../../assets/icons/post/post_filter_on.svg";
import { ReactComponent as Reset } from "../../../../assets/icons/post/reset_btn.svg";
import "./PostStickyHeader.scss";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useTracker } from "../../../../hooks/tracker";

export interface FilterStickyHeaderProps {
  showToolTip?: boolean;
}
const PostStickyHeader = observer(
  ({ showToolTip }: FilterStickyHeaderProps) => {
    const { t } = useTranslation();
    const { selectedTab } = PostStore;

    const [savedShowToolTipFlag, setSavedShowToolTipFlag] =
      useState<boolean>(false);
    const [contentWidth, setContentWidth] = useState<number>(0);
    const [resetWidth, setResetWidth] = useState<number>(0);
    const { track } = useTracker();

    const filterList = PostStore.filterList ?? [];
    // const { width } = Dimensions.get("window");

    // useEffect(() => {
    //   const needShowToolTip = async () => {
    //     const savedShowToolTipFlag = await AsyncStorage.getItem(CATEGORY_TOOLTIP);
    //     if (savedShowToolTipFlag && savedShowToolTipFlag === "false") {
    //       setSavedShowToolTipFlag(false);
    //     } else {
    //       setSavedShowToolTipFlag(showToolTip ?? false);
    //     }
    //   };
    //   void needShowToolTip();
    // }, [showToolTip]);
    interface Tab {
      key: string;
      title?: string;
      accessibilityLabel?: string;
      content?: ReactElement | ((isActive: boolean) => ReactElement);
    }

    const tabList: Tab[] = useMemo(() => {
      return [
        {
          key: "POPULAR", // Default
          title: t("screen.post.label.popular"),
          accessibilityLabel: "popular",
        },
        {
          key: "RECENT",
          title: t("screen.post.label.recently"),
          accessibilityLabel: "recently",
        },
        {
          key: "FOLLOWING",
          title: t("screen.post.label.following"),
          accessibilityLabel: "following",
        },
      ];
    }, []);

    const showGroupFilter = useMemo(() => {
      return selectedTab !== 2;
    }, [selectedTab]);

    const handleTabPress = useCallback(
      async (tab: Tab, index: number) => {
        if (PostStore.loading) return;
        track("change_post_tab", {
          tab_name: tab.key,
        });

        PostStore.setSelectedTab(index);
        setSavedShowToolTipFlag(false);

        await PostStore.initPostsByFilter(true);
      },
      [track]
    );

    return (
      <>
        <div className="post-sticky-header">
          <div className="tab-list">
            {tabList?.map((tab, index) => {
              return (
                <div
                  className="tab-wrapper-style"
                  aria-label={tab.accessibilityLabel}
                  onClick={() => handleTabPress(tab, index)}
                >
                  <div
                    className={
                      PostStore.selectedTab === index
                        ? "tab-text-container-active"
                        : "tab-text-container"
                    }
                  >
                    <span
                      className={clsx(
                        "tab-text",
                        PostStore.selectedTab === index && "tab-text-active"
                      )}
                    >
                      {tab.title}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={
              showGroupFilter
                ? "category-group-scroll-wrapper-with-group"
                : "category-group-scroll-wrapper"
            }
          >
            <div className="category-group-wrap">
              <div className="category-list-wrap">
                <div // TouchableOpacity : On press down, the opacity of the wrapped view is decreased, dimming it.
                  className="category-group-items-icon"
                  onClick={() => {
                    setSavedShowToolTipFlag(false);
                    PostStore.setPostFilterInfo(true, "");
                  }}
                >
                  {PostStore.hasFilterOption ? <FilterOn /> : <FilterOff />}
                </div>

                {filterList?.map((filterGroup, index) => {
                  const isFilterOn =
                    PostStore.filterQuery[filterGroup.codeValue] !== undefined;

                  return (
                    <div // TouchableOpacity
                      key={index}
                      className={clsx(
                        "category-group-items",
                        isFilterOn
                          ? "category-group-items-on"
                          : "category-group-items-off"
                      )}
                      // className={isFilterOn ? "category-group-items-on" : "category-group-items-off"}
                      onClick={() => {
                        setSavedShowToolTipFlag(false);
                        PostStore.setPostFilterInfo(
                          true,
                          filterGroup.codeValue
                        );
                      }}
                    >
                      <span
                        className={
                          isFilterOn
                            ? "category-item-text"
                            : "category-item-text-off"
                        }
                      >
                        {filterGroup.codeName}
                      </span>
                      {isFilterOn ? <ArrowDownOn /> : <ArrowDownOff />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              showGroupFilter && PostStore.hasFilterOption
                ? "category-upper-wrapper-active"
                : "category-upper-wrapper",
              Object.entries(PostStore.filterQuery).length > 0 &&
                "category-filter-wrapper"
            )}
          >
            <div
              className="category-upper-wrapper-container"
              // style={{
              //   backgroundolor: "white",
              //   display: "flex",
              //   overflow-x : "scroll",
              //   marginLeft: 4,
              // }}
              // scrollEnabled={contentWidth > width - resetWidth}
              // onContentSizeChange={onContentSizeChange}
            >
              <div className="category-wrap">
                <div className="category-item-list-wrap">
                  {Object.entries(PostStore.filterQuery).map(
                    ([key, value], index) => {
                      return (
                        <div // touchableOpacity
                          key={index}
                          className="category-items"
                          onClick={() => {
                            void PostStore.deleteFilterOption(key);
                          }}
                        >
                          <span className="category-item-text">
                            {PostStore.subFilterMap?.get(key + "_" + value)}
                          </span>
                          <Close />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            {Object.entries(PostStore.filterQuery)?.length > 0 && (
              <div
                // onLayout={(event) => {
                //   setResetWidth(event.nativeEvent.layout.width);
                // }}
                className="category-reset-view"
              >
                <div
                  className="category-reset"
                  onClick={() => {
                    PostStore.postFilterReset();
                  }}
                >
                  <span className="category-reset-text">
                    {t("screen.post.button.reset")}
                  </span>
                  <Reset />
                </div>
              </div>
            )}
          </div>
          {savedShowToolTipFlag && (
            <div className="category-notice-popover">
              <div
              // onClick={() => {
              //   setSavedShowToolTipFlag(false);
              //   void AsyncStorage.setItem(CATEGORY_TOOLTIP, String(false));
              // }}
              >
                <div className="triangle" />
                <div className="category-notice-popover-inner">
                  <span className="category-notice-popover-text1">
                    다양한 가전코드를 {"\n"}
                    찾을 수 있어요!
                  </span>
                  <CloseToolTip className="category-close-btn" />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default PostStickyHeader;
