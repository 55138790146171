import { observer } from "mobx-react";
import FastImageWithFallback from "./FastImageWithFallback";
import TouchableWithAsyncTask from "./TouchableWithAsyncTask";
import { ReactComponent as NewCircle } from "../../assets/icons/icon_new_circle.svg";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import CommonStore from "../../store/CommonStore";

export interface UserItem {
  memberUuid: string;
  nickname: string;
  profileUrl: string;
  profileBackgroundColor: string;
  notCheckedNewPostYn?: string;
}

type parentType = "AccountMain" | "PostStickyHeader";

const HorizontalUserList = observer(
  ({
    parentType,
    userItemList,
    selectedMemberUuid,
    onPress,
  }: {
    parentType: parentType;
    userItemList: UserItem[];
    selectedMemberUuid?: string;
    onPress: (userItem: UserItem, index: number) => Promise<any>;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    const { widthApp } = CommonStore;
    const cardSlidersettings = {
      infinite: false,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: Math.floor(widthApp / 80),
      slidesToScroll: 1,
      focusOnSelect: false,
    };

    const renderUsers = (item: { item: UserItem; index: number }) => {
      return (
        <>
          <div
            style={{
              position: "relative",
              marginRight: "12px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              ...(item.index === 0 && { marginLeft: "16px" }),
            }}
          >
            {item.item.notCheckedNewPostYn === "Y" && (
              <NewCircle
                style={{
                  position: "absolute",
                  top: -2,
                  right: 1.75,
                  zIndex: 1000,
                }}
              />
            )}
            <TouchableWithAsyncTask
              onPress={() => onPress(item.item, item.index)}
              aria-label={`user${item.index}`}
              style={{
                ...(parentType === "AccountMain"
                  ? {
                      width: 64,
                      height: 64,
                      borderRadius: 32,
                      overflow: "hidden",
                    }
                  : {
                      width: 48,
                      height: 48,
                      borderRadius: 32,
                      overflow: "hidden",
                      ...(item.item.memberUuid === selectedMemberUuid
                        ? {
                            border: "2px solid #BE69C2",
                            padding: "2px",
                          }
                        : { padding: "4px" }),
                    }),
              }}
            >
              <FastImageWithFallback
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 32,
                  overflow: "hidden",
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 32,
                  overflow: "hidden",
                }}
                source={
                  item.item.profileUrl !== undefined &&
                  item.item.profileUrl !== null
                    ? {
                        uri: `${PUBLIC_BUCKET_URL}${item.item.profileUrl}`,
                      }
                    : require("../../assets/images/fall_back_image.png")
                }
              />
            </TouchableWithAsyncTask>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 400,
                letterSpacing: "-0.64px",
                textAlign: "center",
                whiteSpace: "nowrap",
                maxWidth: "52px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "4px",
                ...(item.item.memberUuid === selectedMemberUuid && {
                  color: "#BE69C2",
                }),
              }}
              aria-label="User nickname"
            >
              {item.item.nickname}
            </p>
          </div>
        </>
      );
    };

    return (
      <>
        {userItemList.length > 0 && (
          <>
            <div
              style={{
                paddingTop: 3,
                paddingBottom: 3,
                backgroundColor: "rgb(255, 255, 255)",
              }}
            >
              {parentType === "AccountMain" && (
                <p
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "20px",
                    marginBottom: "10px",
                    fontSize: "16px",
                    letterSpacing: "-0.64px",
                    color: "#222",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {t("screen.myHome.label.similarUser")}
                </p>
              )}

              <Slider {...cardSlidersettings}>
                {userItemList.map((item, index) => {
                  return renderUsers({ item, index });
                })}
              </Slider>
            </div>
          </>
        )}
      </>
    );
  }
);

export default HorizontalUserList;
