import { SuccessOrNot } from "../../constants/Common.enum";
import {
  callAPIWithPOSTMethod,
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPUTMethod,
} from "../common/api";
import CommonResponse from "../http";
import {
  FollowersRequest,
  FollowersResponse,
  FollowingActTypeCode,
  RecommendedFollowingResponse,
} from "./model";

export const follow = async (
  targetMemberUuid: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/follow",
      params: { targetMemberUuid },
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
  );
};

export const unfollow = async (
  targetMemberUuid: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/follow",
      params: { targetMemberUuid },
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
  );
};

export const deleteFollow = async (
  targetMemberUuid: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/follower",
      params: { targetMemberUuid },
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
  );
};

export const getFollowerList = async (
  data: FollowersRequest,
  isLoading = true
): Promise<FollowersResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/follow",
      params: data,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as FollowersResponse;
};

export const getRecommendedFollowings = async (
  isLoading = true
): Promise<RecommendedFollowingResponse[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/follow/recommendedFollowings",
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as RecommendedFollowingResponse[];
};

export const followingActCheck = async (
  actTypeCode: FollowingActTypeCode,
  targetMemberUuid: string,
  isLoading = true
): Promise<CommonResponse> => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/follow/followingActCheck",
      body: { actTypeCode, targetMemberUuid },
    },
    isLoading
  );

  return response?.successOrNot === SuccessOrNot.Y &&
    response?.statusCode === "OK"
    ? response?.data
    : null;
};
