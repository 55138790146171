import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Color } from "../../assets/styles/variable";
import PostStore from "../../store/PostStore";
import PostMainV2 from "./presenter/main/PostMainV2";
import PostFilterBottomSheet from "./presenter/PostFilterBottomSheet";
import FeedScreen from "../feed/FeedScreen";
import CommonStore from "../../store/CommonStore";
import MainLayout from "../main/MainLayout";
import MainStore from "../../store/MainStore";
import { useLocation } from "react-router-dom";

const PostScreen = observer(() => {
  const {
    clear,
    setIsDeepLink,
    setSelectedTab,
    getRecentFeedDetailList,
    getPopularFeedDetailList,
    getFeedFilter,
    postFilterInfo,
    selectedTabType,
    clearExceptPosts,
    selectedFeedIndex,
  } = PostStore;

  const { pathname } = useLocation();

  const initPostData = useCallback(
    (tabType: string) => {
      if (tabType === "RECENT") {
        setSelectedTab(1);
      } else if (tabType === "FOLLOWING") {
        setSelectedTab(2);
      } else {
        setSelectedTab(0);
      }
      void PostStore.initPostsByFilter(true);
    },
    [setSelectedTab]
  );

  useEffect(() => {
    const mainScreenScrollMap = MainStore.mainTabScroll;
    const postPath = pathname.toLowerCase();
    const scrollPosition = mainScreenScrollMap.has(postPath)
      ? mainScreenScrollMap.get(postPath)
      : 0;

    if (selectedFeedIndex === 0 && scrollPosition === 0) {
      clear();
    } else {
      clearExceptPosts();
    }

    let tabType;

    if (selectedTabType) {
      tabType = selectedTabType;
    } else {
      // Default
      tabType = "POPULAR";
    }

    // const tab = data?.route?.params?.tab;
    // if (tab) {
    //   setIsDeepLink(true);
    // }

    // if (tab && (tab === "POPULAR" || tab === "FOLLOWING")) {
    //   tabType = tab;
    // } else {
    //   tabType = "RECENT";
    // }

    if (selectedFeedIndex === 0 && scrollPosition === 0) {
      initPostData(tabType);
    }

    //포스트 필터 조회
    if (!PostStore.filterList) {
      void getFeedFilter();
    }
  }, [
    // setIsDeepLink,
    clear,
    getFeedFilter,
    clearExceptPosts,
    selectedTabType,
  ]);

  return (
    <MainLayout>
      <div id="PostScreen" style={{ flex: 1 }}>
        <PostMainV2
        // tabType={data?.route?.params?.tab}
        // dynamicLinkPosition={data?.route?.params?.location}
        />
        {postFilterInfo.isShow && <PostFilterBottomSheet />}
      </div>
    </MainLayout>
    // {/* {showFeedScreen && (
    //   <div
    //     style={{
    //       width: "100vw",
    //       height: "100vh",
    //       backgroundColor: Color.WHITE,
    //     }}
    //   >
    //     <FeedScreen />
    //   </div>
    // )} */}
  );
});

export default PostScreen;
