import React, { useEffect, useRef, useState, useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TitleHeader } from "../../../components/common";
import { HeaderItem } from "../../../constants/Header";
import AccountMenu from "./AccountMenu";
import AccountHeader from "./AccountHeader";
import { AccountFeedTypeCode } from "../../../constants/Account";
import { MemberInfoResponse } from "../../../api/member/model";
import { ReportTargetTypeCode } from "../../../constants/Report";

import {
  AccountPost,
  AccountQna,
  AccountAB,
  AccountMission,
  AccountMagazine,
} from ".";

import CommonStore from "../../../store/CommonStore";
import ReportStore from "../../../store/ReportStore";
import FeedStore from "../../../store/FeedStore";
import PostStore from "../../../store/PostStore";
import MainStore from "../../../store/MainStore";
import BadgeStore from "../../../store/BadgeStore";
import AccountStore from "../../../store/AccountStore";
import BookmarkStore from "../../../store/BookmarkStore";

import { FeedTypeCode } from "../../../constants/Feed";
import { BadgeCode } from "../../../constants/Badge.enum";
import { RoleTypeCode } from "../../../constants/CommonCode.enum";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
// import RankingStore from "../../../store/RankingStore";

import { ReactComponent as EmptyQna } from "../../../assets/images/EmptyQna.svg";
import { ReactComponent as EmptyPost } from "../../../assets/images/EmptyPost.svg";
import { ReactComponent as EmptyAb } from "../../../assets/images/EmptyAb.svg";
// import Toast from "react-native-toast-message";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import ToastConfig from "../../../components/common/ToastConfig";

// import AdRoundSimpleBanner from "../../../components/common/banner/AdRoundSimpleBanner";
import { TabInterface, Tab } from "../../../components/common/Tabs";

// import CustomCarousel from "../../../components/common/CustomCarousel";
import { BannerInfo, BannerLinkInfo } from "../../../api/banner/model";
import { getBannerList } from "../../../api/banner/api";
import {
  BannerTypeCodes,
  ContentType,
  LinkType,
} from "../../../constants/Banner.enum";

import { openInAppBrowser } from "../../../utils/common";
import { parseQueryParam } from "../../../utils/queryParam";
// import CommonBanner from "../../../components/common/CommonBanner";
import { useTracker } from "../../../hooks/tracker";

import {
  getBrandBannerList,
  getMyHomeBannerList,
} from "../../../api/brand/api";
import { Styles } from "../../../assets/types/Style";
// import { goToLifecare, goToLifecareProductPage } from "../../../utils/mall";
import AuthStore from "../../../store/AuthStore";
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import ListWithStickyTabs from "../../../components/common/ListWithStickyTabs";
import { Virtuoso } from "react-virtuoso";
import "./AccountMain.scss";
import MyHomeStore from "../../../store/MyHomeStore";
import {
  canGoBack,
  goBack,
  goTo,
} from "../../../hooks/navigate/NavigateFunction";
import { useWindowScroll } from "react-use";
import { sendActHistory } from "../../../api/data/api";
import { ActHistoryRequest } from "../../../api/data/model";
import { SimilarUserItem } from "../../../api/account/model";
import { getSimilarUsers } from "../../../api/account/api";
import HorizontalUserList from "../../../components/common/HorizontalUserList";

export interface AccountMainProps {
  isMy: boolean;
  userId: string;
  nickname?: string;
  profileUrl?: string;
  profileBackgroundColor?: string;
  accountInfo: MemberInfoResponse;
  // accountStore?: AccountStore;
  initialActiveTab: AccountFeedTypeCode;
  goBackOnClickHome: boolean;
  isLoading?: boolean;
}

const AccountMain = observer(
  ({
    isMy,
    userId,
    nickname,
    profileUrl,
    profileBackgroundColor,
    accountInfo,
    // accountStore,
    initialActiveTab,
    goBackOnClickHome,
    isLoading,
  }: AccountMainProps) => {
    // TODO

    const { obtainBadge } = BadgeStore;
    const [tabArray, setTabArray] = useState<TabInterface[]>([]);
    const [selectedTab, setSelectedTab] =
      useState<AccountFeedTypeCode>(initialActiveTab);
    const [loading, setLoading] = useState(false);
    const headerRef = useRef<any>();
    const [scrollableHeaderHeight, setScrollableHeaderHeight] = useState();
    const [fixedHeaderTitle, setFixedHeaderTitle] = useState("");
    const [bannerList, setBannerList] = useState<BannerInfo[]>([]);
    const { getAccountStore } = MyHomeStore;
    const accountStore = getAccountStore(userId);
    // const bannerWidth = useResponsiveWidth(100) - 16 - 16;
    const { t } = useTranslation();
    const [similarUsers, setSimilarUsers] = useState([] as SimilarUserItem[]);
    const { track } = useTracker();
    // const inset = useSafeAreaInsets();

    useEffect(() => {
      setSelectedTab(initialActiveTab);
    }, [initialActiveTab]);

    const { setToastOption } = CommonStore;
    const { openReportBottomSheet, reportCompletePoptoTop } = ReportStore;
    const { popPostsFromFeed } = FeedStore;
    const { initPostLists } = PostStore;

    const {
      popFromMainListwithMemberUuid,
      popFromMainPopularListwithMemberUuid,
    } = MainStore;

    const { clearBookmarkStore, initBookmarkPosts } = BookmarkStore;
    // const { reportRankingPoster } = RankingStore;
    const { y } = useWindowScroll();

    useEffect(() => {
      setTimeout(() => {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
          actPageCode: isMy ? ActPageCode.MY_HOME : ActPageCode.OTHER_USER_HOME,
          actSourcePageCode: CommonStore.fixedPreviousRouteName,
          attr1: (y === 0).toString(),
        };
        void sendActHistory(actHistory);
      }, 1000);
    }, []);

    useEffect(() => {
      const scrollY = window.scrollY + 20;
      const headerHeight = headerRef.current.offsetHeight ?? 0;
      const userNickname = nickname ? nickname : accountInfo?.nickname;

      if (headerHeight <= scrollY && !fixedHeaderTitle && userNickname) {
        setFixedHeaderTitle(userNickname);
      }
      if (headerHeight > scrollY && fixedHeaderTitle) {
        setFixedHeaderTitle("");
      }
    }, [y]);

    const moveToTagManagement = () => {
      CommonStore.setShowDownloadAppDrive(true);
      // goTo("TagManagementScreen");
    };
    const moveToBookmark = () => {
      CommonStore.setShowDownloadAppDrive(true);
      // goTo("BookmarkScreen");
    };

    const moveToCoupon = () => {
      CommonStore.setShowDownloadAppDrive(true);
      // ßgoTo("CouponListScreen");
    };

    const moveToAlarm = () => {
      goTo("/NotificationScreen");
    };

    useEffect(() => {
      const normalTab: TabInterface[] = [
        {
          key: AccountFeedTypeCode.POST,
          title: t(`screen.myHome.label.post`),
          content: (
            <AccountPost
              isMy={isMy}
              userId={userId}
              dataRefresh={() => {
                void accountStore?.setPostPageIndex(0);
                void accountStore?.getAccountFeed(0, AccountFeedTypeCode.POST);
              }}
              endReached={onEndReached}
            />
          ),
          accessibilityLabel: "post",
        },
        {
          key: AccountFeedTypeCode.QNA,
          title: t(`screen.myHome.label.qna`),
          content: (
            <AccountQna
              isMy={isMy}
              userId={userId}
              dataRefresh={() => {
                void accountStore?.setQnaPageIndex(0);
                void accountStore?.getAccountFeed(0, AccountFeedTypeCode.QNA);
              }}
              endReached={onEndReached}
            />
          ),
          accessibilityLabel: "qna",
        },
        {
          key: AccountFeedTypeCode.AB,
          title: t(`screen.myHome.label.ab`),
          content: (
            <AccountAB
              isMy={isMy}
              userId={userId}
              dataRefresh={() => {
                void accountStore?.setAbPageIndex(0);
                void accountStore?.getAccountFeed(0, AccountFeedTypeCode.AB);
              }}
              endReached={onEndReached}
            />
          ),
          accessibilityLabel: "ab",
        },
      ];
      const corporateTab: TabInterface[] = [
        {
          key: AccountFeedTypeCode.MISSION,
          title: t(`screen.myHome.label.mission`),
          content: (
            <AccountMission
              isMy={isMy}
              userId={userId}
              dataRefresh={() => {
                void accountStore?.setMissionPageIndex(0);
                void accountStore?.getAccountFeed(
                  0,
                  AccountFeedTypeCode.MISSION
                );
              }}
              endReached={onEndReached}
            />
          ),
        },
        {
          key: AccountFeedTypeCode.MAGAZINE,
          title: t(`screen.myHome.label.magazine`),
          content: (
            <AccountMagazine
              isMy={isMy}
              userId={userId}
              dataRefresh={() => {
                void accountStore?.setMagazinePageIndex(0);
                void accountStore?.getAccountFeed(
                  0,
                  AccountFeedTypeCode.MAGAZINE
                );
              }}
              endReached={onEndReached}
            />
          ),
        },
      ];
      const emptyTab: TabInterface[] = [
        {
          key: AccountFeedTypeCode.POST,
          title: t(`screen.myHome.label.post`),
          content: <EmptyPost />,
          accessibilityLabel: "post",
        },
        {
          key: AccountFeedTypeCode.QNA,
          title: t(`screen.myHome.label.qna`),
          content: <EmptyQna />,
          accessibilityLabel: "qna",
        },
        {
          key: AccountFeedTypeCode.AB,
          title: t(`screen.myHome.label.ab`),
          content: <EmptyAb />,
          accessibilityLabel: "ab",
        },
      ];

      if (accountInfo?.roleTypeCode === RoleTypeCode.CORPORATE) {
        setTabArray([...normalTab, ...corporateTab]);
      } else if (!accountStore) {
        setTabArray(emptyTab);
      } else {
        setTabArray(normalTab);
      }

      //
      // if (accountInfo.roleTypeCode == RoleTypeCode.CORPORATE) {
      //   accountInfo.corporateMemberId &&
      //     void getMyHomeBannerList(
      //       BannerTypeCodes.BRAND_MYHOME_BANNER,
      //       accountInfo.corporateMemberId
      //     ).then((bannerList) => {
      //       setBannerList(
      //         bannerList.length > 10 ? bannerList.slice(0, 10) : bannerList
      //       );
      //     });
      // }
    }, [accountStore, isMy, userId, accountInfo]);

    useEffect(() => {
      // 취향이 비슷한 유저 조회
      if (accountInfo.roleTypeCode === RoleTypeCode.BASIC) {
        void getSimilarUsers({ memberUuid: accountInfo.memberUuid }).then(
          (res) => {
            if (res !== null) setSimilarUsers(res.similarMemberList);
            else setSimilarUsers([]);
          }
        );
      } else {
        setSimilarUsers([]);
      }
    }, [accountInfo.memberUuid, accountInfo.roleTypeCode]);

    // TODO : Badge
    useEffectOnlyOnce(() => {
      if (isMy) {
        void obtainBadge(BadgeCode.JOIN);
        void obtainBadge(BadgeCode.LIKE_BY_OTHERS);
      }
    });

    const FixedHeader = (
      <div className="account-header">
        <TitleHeader
          backColor={
            (profileBackgroundColor
              ? profileBackgroundColor
              : accountInfo?.profileBackgroundColor) || "rgb(103,44,120)"
          }
          title={fixedHeaderTitle}
          isHome
          isHomeWhite
          rightItem={
            !isMy
              ? accountInfo?.roleTypeCode === RoleTypeCode.CORPORATE
                ? HeaderItem.NONE
                : HeaderItem.MORE
              : HeaderItem.MY_COUPON_BOOKMARK
          }
          moreOptionMenuList={
            isMy ? ["TAG", "BOOKMARK", "COUPON", "ALARM"] : ["REPORT"]
          }
          onClickMore={(type) => {
            switch (type) {
              case "TAG":
                moveToTagManagement();
                break;
              case "BOOKMARK":
                moveToBookmark();
                break;
              case "COUPON":
                moveToCoupon();
                break;
              case "ALARM":
                moveToAlarm();
                break;
              case "REPORT":
                openReportBottomSheet(
                  {
                    reportTargetId: accountInfo?.memberUuid,
                    reportTargetTypeCode: ReportTargetTypeCode.MEMBER,
                  },
                  () => {
                    void popPostsFromFeed(accountInfo?.memberUuid);
                    void initPostLists();
                    void popFromMainListwithMemberUuid(accountInfo?.memberUuid);
                    void popFromMainPopularListwithMemberUuid(
                      accountInfo?.memberUuid
                    );
                    void initBookmarkPosts(FeedTypeCode.POST);
                    void initBookmarkPosts(FeedTypeCode.QNA);
                    void initBookmarkPosts(FeedTypeCode.AB);
                    clearBookmarkStore();
                    void reportCompletePoptoTop();
                    // void reportRankingPoster(accountInfo?.memberUuid);

                    if (canGoBack()) goBack();
                  }
                );
                break;
              default:
                break;
            }
          }}
        />
      </div>
    );

    // TODO
    // const handleBannerPress = (index: number, bannerInfo: BannerInfo) => {
    //   const bannerLinkInfo = parseQueryParam(bannerInfo.bannerLinkParameter);
    //   const { linkUrl, linkType, contentId } = bannerLinkInfo;
    //   if (bannerLinkInfo.linkType === LinkType.INTERNAL) {
    //     const needLogin = AuthStore.sessionUser?.memberUuid === undefined;
    //     switch (bannerLinkInfo.contentType) {
    //       case ContentType.POST:
    //         navigate("PostDetailScreen", {
    //           feedId: contentId,
    //           postIndex: index,
    //         });
    //         break;
    //       case ContentType.MAGAZINE:
    //         navigate("MagazineDetailScreen", { feedId: contentId });
    //         break;
    //       case ContentType.QNA:
    //         navigate("QnaDetailScreen", { feedId: contentId });
    //         break;
    //       case ContentType.MISSION:
    //         needLogin
    //           ? navigate("JoinModal")
    //           : navigate("MissionDetailScreen", { missionId: contentId });
    //         break;
    //       case ContentType.AB:
    //         navigate("AbDetailScreen", { feedId: contentId });
    //         break;
    //       case ContentType.PRODUCT:
    //         navigate("ProductDetailScreen", { productId: contentId });
    //         break;
    //       case ContentType.MY_HOME:
    //         needLogin ? navigate("JoinModal") : navigate("MyHomeScreen");
    //         break;
    //       case ContentType.MAGAZINE_HOME:
    //         navigate("MagazineScreen");
    //         break;
    //       case ContentType.RANKING:
    //         navigate("RankingScreen");
    //         break;
    //       case ContentType.MISSION_HOME:
    //         navigate("MissionStack");
    //         break;
    //       case ContentType.QNA_HOME:
    //         navigate("QnaStack");
    //         break;
    //       case ContentType.QNA_LIST:
    //         navigate("QnaListScreen");
    //         break;
    //       case ContentType.AB_LIST:
    //         navigate("AbListScreen");
    //         break;
    //       case ContentType.POST_HOME:
    //         navigate("PostStack");
    //         break;
    //       case ContentType.SETTING:
    //         needLogin ? navigate("JoinModal") : navigate("Setting");
    //         break;
    //       case ContentType.NOTICE:
    //         navigate("Notice");
    //         break;
    //       case ContentType.FRIEND_RECOMMEND:
    //         navigate("FriendRecommendScreen");
    //         break;
    //       case ContentType.BRAND_HOME:
    //         navigate("MainTab", {
    //           screen: "BrandCommunityHome",
    //           params: { targetBrandId: contentId?.toString() },
    //         });
    //         break;
    //       case ContentType.BRAND_COMMUNITY_MAIN:
    //         navigate("MainTab", {
    //           screen: "BrandCommunityMain",
    //         });
    //         break;
    //       case ContentType.BRAND_COMMUNITY_POST:
    //         navigate("ArticleDetailScreen", { articleId: contentId });
    //         break;
    //       case ContentType.MALL_DISPLAY_PAGE:
    //         navigate("MainTab", {
    //           screen: "MallMainScreen",
    //         });
    //         break;
    //       case ContentType.MALL_PRODUCT:
    //         goToLifecareProductPage(contentId || 0);
    //         break;
    //       case ContentType.MALL_CATEGORY:
    //         navigate("MainTab", {
    //           screen: "LargeCategoryScreen",
    //           params: { categoryId: contentId },
    //         });
    //         break;
    //       default:
    //         break;
    //     }
    //   } else if (linkType === LinkType.MALL) {
    //     goToLifecare(linkUrl || "/");
    //   } else {
    //     void openInAppBrowser(linkUrl || "");
    //   }
    // };

    // TODO
    // const renderBannerItem = ({
    //   item,
    //   index,
    // }: {
    //   item: BannerInfo;
    //   index: number;
    // }) => {
    //   return (
    //     <AdRoundSimpleBanner
    //       imageUrl={`${Config.REACT_NATIVE_PUBLIC_BUCKET_URL}${item.filePath}`}
    //       onPress={() => handleBannerPress(index, item)}
    //       width={bannerWidth}
    //       height={120}
    //     />
    //   );
    // };

    const ScrollableHeader = (
      <div className="account-header-full-wrapper" ref={headerRef}>
        <AccountHeader
          isMy={isMy}
          nickname={nickname}
          profileUrl={profileUrl}
          profileBackgroundColor={profileBackgroundColor}
          accountInfo={accountInfo}
          isLoading={isLoading}
        />
        {isMy && accountInfo.roleTypeCode != RoleTypeCode.CORPORATE && (
          <AccountMenu isMy={isMy} accountInfo={accountInfo} />
        )}
        {/* TODO 법인 계정 */}
        {/* {accountInfo.roleTypeCode == RoleTypeCode.CORPORATE &&
          bannerList.length > 0 && (
            <CustomCarousel
              windowSize={3}
              initialNumToRender={10}
              style={{ marginTop: 16 }}
              accessibilityLabel={"banner carousel"}
              data={bannerList}
              renderItem={(item) => renderBannerItem(item)}
              showTextIndicator
              textIndexContainerStyle={styles.carousel_idx}
              textIndexStyle={styles.carousel_idx_text}
              dotContainerStyle={{ marginTop: -28 }}
              inactiveDotStyle={{
                backgroundColor: "rgba(34,34,34,0.4)",
                marginHorizontal: 3,
              }}
              dotStyle={{ marginHorizontal: 3, backgroundColor: "#fff" }}
            />
          )} */}

        <HorizontalUserList
          parentType={"AccountMain"}
          userItemList={similarUsers}
          onPress={async (userItem, index) => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.USER_CLICK,
              userItem.memberUuid,
              ActPageCode.MY_HOME,
              ActSectionCode.SIMILAR_USER,
              index.toString()
            );
            goTo(`/user/${userItem.nickname}`, {
              state: {
                targetUserId: userItem.memberUuid,
                nickname: userItem.nickname,
                profileUrl: userItem.profileUrl,
                profileBackgroundColor: userItem.profileBackgroundColor,
              },
            });
          }}
        />
      </div>
    );

    const onEndReached = async () => {
      if (!loading && userId) {
        setLoading(true);
        if (
          selectedTab === AccountFeedTypeCode.POST &&
          accountStore?.postCanLoadMore
        ) {
          await accountStore?.getAccountFeed(
            accountStore?.postPageIndex + 1,
            AccountFeedTypeCode.POST
          );
          accountStore?.setPostPageIndex(accountStore?.postPageIndex + 1);
        } else if (
          selectedTab === AccountFeedTypeCode.QNA &&
          accountStore?.qnaCanLoadMore
        ) {
          await accountStore?.getAccountFeed(
            accountStore?.qnaPageIndex + 1,
            AccountFeedTypeCode.QNA
          );
          accountStore?.setQnaPageIndex(accountStore?.qnaPageIndex + 1);
        } else if (
          selectedTab === AccountFeedTypeCode.AB &&
          accountStore?.abCanLoadMore
        ) {
          await accountStore?.getAccountFeed(
            accountStore?.abPageIndex + 1,
            AccountFeedTypeCode.AB
          );
          accountStore?.setAbPageIndex(accountStore?.abPageIndex + 1);
        } else if (
          selectedTab === AccountFeedTypeCode.MAGAZINE &&
          accountStore?.magazineCanLoadMore
        ) {
          await accountStore?.getAccountFeed(
            accountStore?.magazinePageIndex + 1,
            AccountFeedTypeCode.MAGAZINE
          );
          accountStore?.setMagazinePageIndex(
            accountStore?.magazinePageIndex + 1
          );
        } else if (
          selectedTab === AccountFeedTypeCode.MISSION &&
          accountStore?.missionCanLoadMore
        ) {
          await accountStore?.getAccountFeed(
            accountStore?.missionPageIndex + 1,
            AccountFeedTypeCode.MISSION
          );
          accountStore?.setMissionPageIndex(accountStore?.missionPageIndex + 1);
        }

        setLoading(false);
      }
    };

    useEffect(() => {
      switch (selectedTab) {
        case AccountFeedTypeCode.POST:
          accountStore?.postList?.length === 0 &&
            accountStore?.getAccountFeed(
              accountStore?.postPageIndex,
              selectedTab
            );
          break;
        case AccountFeedTypeCode.QNA:
          accountStore?.qnaList?.length === 0 &&
            accountStore?.getAccountFeed(
              accountStore?.qnaPageIndex,
              selectedTab
            );
          break;
        case AccountFeedTypeCode.AB:
          accountStore?.abList.length === 0 &&
            accountStore?.getAccountFeed(
              accountStore?.abPageIndex,
              selectedTab
            );
          break;
        case AccountFeedTypeCode.MISSION:
          accountStore?.missionList.length === 0 &&
            accountStore?.getAccountFeed(
              accountStore?.missionPageIndex,
              selectedTab
            );
          break;
        case AccountFeedTypeCode.MAGAZINE:
          accountStore?.magazineList.length === 0 &&
            accountStore?.getAccountFeed(
              accountStore?.magazinePageIndex,
              selectedTab
            );
          break;

        default:
          break;
      }
    }, [selectedTab, accountStore]);

    const stickyTabsRef = useRef<typeof Virtuoso>(null);

    return (
      <>
        <div
          id="account-main-wrapper"
          style={{
            backgroundColor: "white",
            flex: 1,
            flexDirection: "column",
          }}
        >
          {FixedHeader}
          <ListWithStickyTabs
            className="list-sticky-tabs-my-home-wrapper"
            contentScrollRef={stickyTabsRef}
            scrollableHeader={ScrollableHeader}
            stickyTabs={tabArray}
            onChangeTab={(key: string) => {
              if (accountStore !== undefined) {
                setSelectedTab(key as AccountFeedTypeCode);
              }
            }}
            activeTabKey={selectedTab}
          />
        </div>
      </>
    );
  }
);

export default AccountMain;
