import { Method } from "axios";
import { Service } from "../../constants/Service";
import CommonResponse, { getInstance } from "../http";
import { WEB_UNIQUE_KEY } from "../../constants/Storage";
export interface APIContract {
  method: Method;
  url: string;
  params?: object;
  body?: object;
  service?: Service;
}

export const callAPIWithGETMethod = async <T = any>(
  { ...contract }: Omit<APIContract, "method">,
  isLoading: boolean
): Promise<CommonResponse<T>> => {
  if (!contract.params || !("webUniqueKey" in contract.params)) {
    // webUniqueKey 주입 케이스 : contract.params가 없거나, 있는데 'webUniqueKey'가 없는 경우
    contract.params = {
      ...contract.params,
      webUniqueKey: localStorage.getItem(WEB_UNIQUE_KEY),
    };
  }

  return await callAPI<T>(
    {
      method: "GET",
      ...contract,
    },
    isLoading
  );
};

export const callAPIWithPOSTMethod = async <T = any>(
  { ...contract }: Omit<APIContract, "method">,
  isLoading: boolean,
  showToast?: boolean
): Promise<CommonResponse<T>> => {
  if (!contract.body || !("webUniqueKey" in contract.body)) {
    // webUniqueKey 주입 케이스 : contract.body가 없거나, 있는데 'webUniqueKey'가 없는 경우
    contract.body = {
      ...contract.body,
      webUniqueKey: localStorage.getItem(WEB_UNIQUE_KEY),
    };
  }
  return await callAPI<T>(
    {
      method: "POST",
      ...contract,
    },
    isLoading,
    showToast
  );
};

export const callAPIWithPUTMethod = async <T = any>(
  { ...contract }: Omit<APIContract, "method">,
  isLoading: boolean
): Promise<CommonResponse<T>> => {
  if (!contract.body || !("webUniqueKey" in contract.body)) {
    // webUniqueKey 주입 케이스 : contract.body가 없거나, 있는데 'webUniqueKey'가 없는 경우
    contract.body = {
      ...contract.body,
      webUniqueKey: localStorage.getItem(WEB_UNIQUE_KEY),
    };
  }
  return await callAPI<T>(
    {
      method: "PUT",
      ...contract,
    },
    isLoading
  );
};

export const callAPIWithDELETEMethod = async <T = any>(
  { ...contract }: Omit<APIContract, "method">,
  isLoading: boolean
): Promise<CommonResponse<T>> => {
  if (!contract.body || !("webUniqueKey" in contract.body)) {
    // webUniqueKey 주입 케이스 : contract.body가 없거나, 있는데 'webUniqueKey'가 없는 경우
    contract.body = {
      ...contract.body,
      webUniqueKey: localStorage.getItem(WEB_UNIQUE_KEY),
    };
  }
  return await callAPI<T>(
    {
      method: "DELETE",
      ...contract,
    },
    isLoading
  );
};

export const callAPI = async <T = any>(
  { method, url, params, body, service = Service.MOGA_BE }: APIContract,
  isLoading = true,
  showToast = true
): Promise<CommonResponse<T>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  try {
    response = await getInstance(service, isLoading, showToast).request({
      url,
      method,
      params: { ...params },
      data: { ...body },
      timeout: 10000,
    });
  } catch (error) {
    response.data = error;
  }

  return response as CommonResponse<T>;
};
